<template>
  <div class="section-header">
    <div class="header-left">
      <div class="header-title">일별 락카정보</div>
    </div>
    <div class="header-right">
      <ul class="header-button">
        <li
          v-for="{
            comCode,
            comName,
            colorValue,
          } in lockerStatusCommonCodes.filter(
            ({ comCode: _comCode }) =>
              _comCode !== 'CHECKIN' &&
              _comCode !== 'CHECKOUT' &&
              _comCode !== 'NOT'
          )"
          v-bind:key="`locker-daily-info-header-button-${comCode}`"
          :class="`set-${comCode.toLowerCase()}`"
          :style="{ backgroundColor: colorValue }"
        >
          <erp-button
              button-div="GET"
              :is-key-color="false"
              :is-icon-custom="true"
              v-on:click.native="handleSetLockerStatusClick(comCode)"
          >
            {{ comName }}
          </erp-button>
        </li>
        <li class="add-batch create">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              v-on:click.native="handleOpenCreateLockerInfoInBatchPopupClick"
          >
            일괄락카생성
          </erp-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LockerMonthlyInformationHeader",
  mixins: [commonMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      lockerStatusCommonCodes: commonCodesGetCommonCode("LOCKER_STATUS"),
    };
  },
  methods: {
    handleSetLockerStatusClick(lockerStatus) {
      this.$emit("setLockerStatusClick", lockerStatus);
    },
    handleOpenCreateLockerInfoInBatchPopupClick() {
      this.$emit("openCreateLockerInfoInBatchPopupClick");
    },
  },
};
</script>

<style scoped></style>
