<template>
  <div
      id="powder-wrapper"
      class="content-body"
      @mousedown="onMouseDown"
  >
    <div
        v-if="propsLoading"
        class="loader"
    />
    <div class="powder-box" :style="{ zoom }">
      <div
          v-for="(item, index) in backInfo"
          :key="index"
          class="back-info"
          :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing,
          backgroundColor: item.backgroundColor,
          color: item.color,
          fontSize: `${propsIsFullscreen ? item.fontSize : (item.mFontSize || item.fontSize)}`,
          borderStyle: item.borderStyle,
          borderLeftWidth: `${(item.bl || 0)}px`,
          borderRightWidth: `${(item.br || 0)}px`,
          borderTopWidth: `${(item.bt || 0)}px`,
          borderBottomWidth: `${(item.bb || 0)}px`,
        }"
      >
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
            v-for="data in propsLockerStatusList"
            :key="`${data.sector}_${data.lockerDiv}`"
            :class="`sector sector${data.sector}`"
        >
          <div
              v-for="board in data.lockerBoardList"
              :key="board.lockerNo"
              :title="board.chkinTime"
              :class="[
              'locker',
              `locker${board.lockerNo}`,
              propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                ? 'search rainbow'
                : undefined,
              isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                ? 'clear'
                : undefined,
            ]"
              :style="{
              left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 48 : 45)}px`,
              top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
              ...getColorValue(board),
            }"
              @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
      <div
          v-show="isLockerKeepVisitorStatus"
          style="position: absolute; left: 1230px; top: 523px; width: 260px; height: 267px; border: 0px solid;"
      >
        <div
            class="div-header"
            style="background-color: #2e7458; line-height: 35px; border-radius: 1px 1px 0 0;"
        >
          <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
              style="cursor: pointer; left: 0; width: 33px; height: 35px; padding: 0; background: transparent; border-color: transparent; border-radius: 50%; bottom: 0; float: right;position: relative; box-shadow: none; display: inline-block; justify-content: center; vertical-align: middle; white-space: nowrap;"
              @click="isLockerKeepVisitorStatus = !isLockerKeepVisitorStatus"
          >
            <span
                style="width: 100%; height: 100%; margin: 0; opacity: 0.5; font-size: 12px; display: inline-block; vertical-align: middle; background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMzMTRFQjU5QkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMzMTRFQjVBQkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzMxNEVCNTdCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzMxNEVCNThCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz520KoJAAAAQ0lEQVR42nTOUQ4AMAQDUHr/O3dbIgtN+cMTTd6KiIy9iALcwNujGgf/B7RhhyMC5DoVOET3Gi6DQrgMCrGAAY8AAwDe6xQOLXhdxAAAAABJRU5ErkJggg==) no-repeat center center;"
            ></span>
          </erp-button>
          <div
              class="header-title"
              style="padding: 0 0 0 12px; color: #fff; font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif; font-weight: normal; font-size: 16px; width: calc(100% - 63px);"
          >
            전일 락카보관 현황
          </div>
        </div>
        <div
            class="body-grid"
            style="height: calc(100% - 24px);"
        >
          <ejs-grid-wrapper
              :provides="grid"
              :allowResizing="false"
              :allowSorting="false"
              :allowFiltering="false"
              :allowEditing="false"
              :dataSource="lockerKeepVisitorList"
              :columns="lockerKeepVisitorGridColumns"
              style="height: 100%;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 44px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Resize,
  ForeignKey,
  Aggregate,
  Filter,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import {getVisitCopy} from "@/api/frontManagementCheckin";
import {getDateOfPrevious} from "@/utils/date";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "LockerStatusLD1",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom","propsTotalLockerArray","propsVariableLockerGenderLD1","propsVariableLockerGenderLD2","propsLockerStatusList", "propsSearchValue", "bsnDate"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      zoom: this.propsIsFullscreen ? 0.81 : 1,
      isLockerKeepVisitorStatus: true,
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      lockerKeepVisitorGridColumns: [
        {
          field: "lockerNo",
          headerText: "락카",
          width: "60",
          textAlign: "center",
        },
        {
          field: "visitName",
          headerText: "내장객명",
          width: "70",
        },
        {
          field: "sexCode",
          headerText: "성별",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "SEX_CODE",
        }
      ],
      lockerKeepVisitorList: [],
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          1,
          false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          2,
          false,
      ),
    };
  },
  computed: {
    backInfo() {
      return [
        {
          w: 200,
          h: 68,
          x: 250,
          y: 200,
          mw: 200,
          mh: 37,
          mx: 250,
          my: 110,
          text: '남 자 락 카',
          wordSpacing: 200,
        },
        {
          w: 200,
          h: 20,
          x: 910,
          y: 50,
          mw: 200,
          mh: 16,
          mx: 850,
          my: 46,
          text: `${this.findLockerByNumber("183","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("183","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 95,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 85,
          text: `${this.findLockerByNumber("191","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("191","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 160,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 141,
          text: `${this.findLockerByNumber("201","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("201","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 225,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 198,
          text: `${this.findLockerByNumber("220","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("220","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 292,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 255,
          text: `${this.findLockerByNumber("240","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("240","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 357,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 312,
          text: `${this.findLockerByNumber("259","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("259","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 423,
          mw: 200,
          mh: 16,
          mx: 730,
          my: 369,
          text: `${this.findLockerByNumber("291","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("291","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 20,
          x: 770,
          y: 488,
          mw: 200,
          mh: 16,
          mx: 770,
          my: 425,
          text: `${this.findLockerByNumber("330","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("330","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 250,
          y: 340,
          mw: 200,
          mh: 20,
          mx: 215,
          my: 299,
          bl: 1,
          br:1,
          bt:1,
          bb:1,
          borderStyle: 'solid',
          text: `가변락카 40EA(${this.propsVariableLockerGenderLD1})`,
          fontSize: '20px'
        },
        {
          w: 200,
          h: 30,
          x: 370,
          y: 340,
          mw: 200,
          mh: 20,
          mx: 330,
          my: 299,
          text: `${this.findLockerByNumber("283","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("283","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 240,
          y: 418,
          mw: 200,
          mh: 20,
          mx: 215,
          my: 367,
          text: `${this.findLockerByNumber("303","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("303","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 240,
          y: 487,
          mw: 200,
          mh: 20,
          mx: 215,
          my: 423,
          text: `${this.findLockerByNumber("341","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("341","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 383,
          h: 196,
          x: 146,
          y: 334,
          mw: 358,
          mh: 171,
          mx: 139,
          my: 289,
          bl: 2,
          br:1,
          bt:2,
          bb:2,
          borderStyle: 'solid',
        },
        {
          w: 574,
          h: 196,
          x: 532,
          y: 334,
          mw: 540,
          mh: 113,
          mx: 500,
          my: 289,
          bl: 1,
          br: 0,
          bt: 2,
          bb: 1,
          borderStyle: 'solid',
        },
        // {
        //   w: 574,
        //   h: 64,
        //   x: 532,
        //   y: 466,
        //   mw: 540,
        //   mh: 55,
        //   mx: 500,
        //   my: 405,
        //   bl: 1,
        //   br: 0,
        //   bt: 2,
        //   bb: 2,
        //   borderStyle: 'solid',
        // },
        {
          w: 200,
          h: 20,
          x: 652,
          y: 357,
          mw: 200,
          mh: 16,
          mx: 652,
          my: 424,
          bl: 1,
          br:1,
          bt:1,
          bb:1,
          borderStyle: 'solid',
          text: `가변락카 68EA(${this.propsVariableLockerGenderLD2})`,
          fontSize: '18px'
        },
        {
          w: 91,
          h: 40,
          x: 1107,
          y: 491,
          mw: 91,
          mh: 40,
          mx: 1041,
          my: 422,
          bl: 0,
          br: 2,
          bt: 0,
          bb: 2,
          borderStyle: 'solid',
        },
        {
          w: 80,
          h: 35,
          x: 1114,
          y: 492,
          mw: 80,
          mh: 35,
          mx: 1048,
          my: 423,
          bl: 1,
          br:1,
          bt:1,
          bb:1,
          borderStyle: 'solid',
          text: '카운터',
          fontSize: '20px'
        },
        {
          w: 478,
          h: 242,
          x: 627,
          y: 91,
          mw: 478,
          mh: 208,
          mx: 588,
          my: 81,
          bl: 2,
          br:0,
          bt:0,
          bb:0,
          borderStyle: 'solid',
        },
        {
          w: 287,
          h: 64,
          x: 628,
          y: 25,
          mw: 270,
          mh: 56,
          mx: 588,
          my: 24,
          bl: 0,
          br:2,
          bt:0,
          bb:2,
          borderStyle: 'solid',
        },
        {
          w: 190,
          h: 64,
          x: 917,
          y: 25,
          mw: 183,
          mh: 64,
          mx: 858,
          my: 22,
          bl: 0,
          br:0,
          bt:2,
          bb:0,
          borderStyle: 'solid',
        },
        {
          w: 382,
          h: 526,
          x: 1493,
          y: 69,
          mw: 360,
          mh: 457,
          mx: 1400,
          my: 60,
          bl: 0,
          br: 2,
          bt: 2,
          bb: 2,
          borderStyle: 'solid',
        },
        {
          w: 200,
          h: 30,
          x: 1460,
          y: 88,
          mw: 200,
          mh: 20,
          mx: 1370,
          my: 82,
          text: `${this.findLockerByNumber("155","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("155","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1460,
          y: 155,
          mw: 200,
          mh: 20,
          mx: 1370,
          my: 140,
          text: `${this.findLockerByNumber("128","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("128","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1460,
          y: 221,
          mw: 200,
          mh: 20,
          mx: 1370,
          my: 197,
          text: `${this.findLockerByNumber("100","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("100","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1460,
          y: 288,
          mw: 200,
          mh: 20,
          mx: 1370,
          my: 253,
          text: `${this.findLockerByNumber("73","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("73","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1460,
          y: 355,
          mw: 200,
          mh: 20,
          mx: 1370,
          my: 310,
          text: `${this.findLockerByNumber("45","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("45","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1600,
          y: 421,
          mw: 200,
          mh: 20,
          mx: 1500,
          my: 367,
          text: `${this.findLockerByNumber("26","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("26","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1600,
          y: 485,
          mw: 200,
          mh: 20,
          mx: 1500,
          my: 424,
          text: `${this.findLockerByNumber("14","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("14","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 1600,
          y: 551,
          mw: 200,
          mh: 20,
          mx: 1500,
          my: 481,
          text: `${this.findLockerByNumber("1","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("1","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 288,
          h: 372,
          x: 1205,
          y: 69,
          mw: 269,
          mh: 328,
          mx: 1131,
          my: 60,
          bl: 0,
          br: 0,
          bt: 2,
          bb: 2,
          borderStyle: 'solid',
        },
        {
          w: 288,
          h: 154,
          x: 1203,
          y: 445,
          mw: 288,
          mh: 128,
          mx: 1110,
          my: 392,
          bl: 0,
          br: 2,
          bt: 0,
          bb: 0,
          borderStyle: 'solid',
        },
        {
          w: 80,
          h: 35,
          x: 1240,
          y: 450,
          mw: 80,
          mh: 35,
          mx: 1140,
          my: 390,
          text: '입구',
          fontSize: '20px'
        },
        {
          w: 1920,
          h: 600,
          x: 0,
          y: 0,
          mw: 1920,
          mh: 519,
          mx: 0,
          backgroundColor: `rgba(135, 206, 235, .5)`,
        },
        // 최소화시 우측 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 1888,
          y: 0,
          mx: 1758,
        },
        {
          w: 1060,
          h: 400,
          x: 0,
          y: 600,
          mw: 995,
          mh: 476,
          mx: 0,
          my: 519,
          backgroundColor: `rgba(255,192,203,0.5)`,
        },
        {
          w: 80,
          h: 80,
          x: 590,
          y: 775,
          mw: 50,
          mh: 25,
          mx: 590,
          my: 695,
          text: '입구',
          fontSize: '20px',
        },
        {
          w: 80,
          h: 80,
          x: 540,
          y: 840,
          mw: 70,
          mh: 50,
          mx: 511,
          my: 740,
          text: '카운터',
          fontSize: '20px',
        },
        {
          w: 200,
          h: 200,
          x: 630,
          y: 800,
          mw: 200,
          mh: 37,
          mx: 590,
          my: 777,
          text: '여 자 락 카',
          wordSpacing: 200,
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 617,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 538,
          text: `${this.findLockerByNumber("758","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("758","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 683,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 595,
          text: `${this.findLockerByNumber("747","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("747","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 749,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 652,
          text: `${this.findLockerByNumber("735","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("735","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 815,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 709,
          text: `${this.findLockerByNumber("724","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("724","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 881,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 766,
          text: `${this.findLockerByNumber("712","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("712","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 192,
          y: 947,
          mw: 200,
          mh: 20,
          mx: 175,
          my: 823,
          text: `${this.findLockerByNumber("701","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("701","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 575,
          y: 610,
          mw: 200,
          mh: 20,
          mx: 535,
          my: 530,
          text: `${this.findLockerByNumber("770","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("770","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 575,
          y: 685,
          mw: 200,
          mh: 20,
          mx: 535,
          my: 598,
          text: `${this.findLockerByNumber("776","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("776","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 200,
          h: 30,
          x: 575,
          y: 750,
          mw: 200,
          mh: 20,
          mx: 535,
          my: 652,
          text: `${this.findLockerByNumber("786","sector")}`,
          color:commonCodesGetColorValue("LOCKER_DIV",this.findLockerByNumber("786","lockerDiv")),
          fontSize: '18px'
        },
        {
          w: 382,
          h: 394,
          x: 147,
          y: 597,
          mw: 360,
          mh: 343,
          mx: 138,
          my: 516,
          bl: 2,
          br: 0,
          bt: 2,
          bb: 2,
          borderStyle: 'solid',
        },
        {
          w: 288,
          h: 196,
          x: 531,
          y: 597,
          mw: 272,
          mh: 174,
          mx: 500,
          my: 516,
          bl: 0,
          br: 2,
          bt: 2,
          bb: 2,
          borderStyle: 'solid',
        },
        {
          w: 96,
          h: 130,
          x: 531,
          y: 861,
          mw: 87,
          mh: 110,
          mx: 500,
          my: 749,
          bl: 0,
          br: 2,
          bt: 2,
          bb: 2,
          borderStyle: 'solid',
        },
      ];
    },
  },
  watch: {
    propsZoom() {
      this.zoom = this.propsZoom;
    },
  },
  async mounted() {
    await this.$nextTick();
    this.lockerKeepVisitorList = [];
    const response = await getVisitCopy(getDateOfPrevious(this.bsnDate));
    response?.value?.map(value => {
      value?.visitInfoList?.map(visitor => {
        this.lockerKeepVisitorList.push(visitor);
      });
    });

    if (!(this.lockerKeepVisitorList.length > 0)) {
      this.isLockerKeepVisitorStatus = false;
    }
  },
  methods: {
    findLockerByNumber(number,key) {
      const findLocker = this.propsTotalLockerArray.find(({lockerNo}) => lockerNo === number);
      return findLocker[key];
    },
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
          ? print.substr(0, 3)
          : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
          ? '#9764b6'
          : !["CHECKIN","ASSIGN","KEEP"].includes(board.lockerStatus) && board.vipFlag
              ? '#AAF0D1'
              : (commonCodesGetColorValue(
                  'LOCKER_STATUS',
                  board.lockerStatus === "KEEP" ? "RESVE" : board.lockerStatus,
              ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
          : null;
    },
    showLockerKeepVisitorStatus() {
      this.isLockerKeepVisitorStatus = true;
    }
  }
};
</script>