<template>
  <div
      id="powder-wrapper"
      class="content-body"
      @mousedown="onMouseDown"
  >
    <div
        v-if="propsLoading"
        class="loader"
    />
    <div class="powder-box" :style="{ zoom }">
      <div
          v-for="(item, index) in backInfo"
          :key="index"
          class="back-info"
          :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing,
          backgroundColor: item.backgroundColor,
          color: item.color,
          fontSize: `${propsIsFullscreen ? item.fontSize : (item.mFontSize || item.fontSize)}`,
        }"
      >
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
            v-for="data in propsLockerStatusList"
            :key="`${data.sector}_${data.lockerDiv}`"
            :class="`sector sector${data.sector}`"
        >
          <div
              v-for="board in data.lockerBoardList"
              :key="board.lockerNo"
              :class="[
              'locker',
              `locker${board.lockerNo}`,
              propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                ? 'search rainbow'
                : undefined,
              isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                ? 'clear'
                : undefined,
            ]"
              :style="{
              left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 48 : 45)}px`,
              top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
              ...getColorValue(board),
            }"
              @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
      <div
          v-show="isLockerKeepVisitorStatus"
          style="position: absolute; left: 1230px; top: 523px; width: 260px; height: 267px; border: 0px solid;"
      >
        <div
            class="div-header"
            style="background-color: #2e7458; line-height: 35px; border-radius: 1px 1px 0 0;"
        >
          <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
              style="cursor: pointer; left: 0; width: 33px; height: 35px; padding: 0; background: transparent; border-color: transparent; border-radius: 50%; bottom: 0; float: right;position: relative; box-shadow: none; display: inline-block; justify-content: center; vertical-align: middle; white-space: nowrap;"
              @click="isLockerKeepVisitorStatus = !isLockerKeepVisitorStatus"
          >
            <span
                style="width: 100%; height: 100%; margin: 0; opacity: 0.5; font-size: 12px; display: inline-block; vertical-align: middle; background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMzMTRFQjU5QkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMzMTRFQjVBQkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzMxNEVCNTdCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzMxNEVCNThCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz520KoJAAAAQ0lEQVR42nTOUQ4AMAQDUHr/O3dbIgtN+cMTTd6KiIy9iALcwNujGgf/B7RhhyMC5DoVOET3Gi6DQrgMCrGAAY8AAwDe6xQOLXhdxAAAAABJRU5ErkJggg==) no-repeat center center;"
            ></span>
          </erp-button>
          <div
              class="header-title"
              style="padding: 0 0 0 12px; color: #fff; font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif; font-weight: normal; font-size: 16px; width: calc(100% - 63px);"
          >
            전일 락카보관 현황
          </div>
        </div>
        <div
            class="body-grid"
            style="height: calc(100% - 24px);"
        >
          <ejs-grid-wrapper
              :provides="grid"
              :allowResizing="false"
              :allowSorting="false"
              :allowFiltering="false"
              :allowEditing="false"
              :dataSource="lockerKeepVisitorList"
              :columns="lockerKeepVisitorGridColumns"
              style="height: 100%;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 42px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Resize,
  ForeignKey,
  Aggregate,
  Filter,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import {getVisitCopy} from "@/api/frontManagementCheckin";
import {getDateOfPrevious} from "@/utils/date";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "LockerStatusED1",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom", "propsLockerStatusList", "propsSearchValue", "bsnDate"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  watch: {
    propsZoom() {
      this.zoom = this.propsZoom;
    },
  },
  data() {
    return {
      zoom: 1,
      isLockerKeepVisitorStatus: true,
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      lockerKeepVisitorGridColumns: [
        {
          field: "lockerNo",
          headerText: "락카",
          width: "60",
          textAlign: "center",
        },
        {
          field: "visitName",
          headerText: "내장객명",
          width: "70",
        },
        {
          field: "sexCode",
          headerText: "성별",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "SEX_CODE",
        }
      ],
      lockerKeepVisitorList: [],
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          1,
          false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          2,
          false,
      ),
    };
  },
  computed: {
    backInfo() {
      return [
        {w: 155,  h: 8,   x: 60,   y: 400, fontSize: '20px', color: `rgb(0,0,255)`  , wordSpacing: 200, text: '→ 입구'}, //남자
        {w: 155,  h: 8,   x: 60,   y: 840, fontSize: '20px', color: `rgb(254,3,254)`, wordSpacing: 200, text: '→ 입구'}, //여자
        {w: 155,  h: 8,   x: 1610, y: 180, fontSize: '28px', color: `rgb(0,0,255)`  , wordSpacing: 200, text: 'VIP락카'},//남자
        {w: 155,  h: 8,   x: 1500, y: 500, fontSize: '28px', color: `rgb(0,0,255)`  , wordSpacing: 200, text: 'VIP락카'},//남자
        {w: 155,  h: 8,   x: 1510, y: 740, fontSize: '28px', color: `rgb(254,3,254)`, wordSpacing: 200, text: 'VIP락카'},//여자
        {w: 155,  h: 8,   x: 1640, y: 940, fontSize: '28px', color: `rgb(254,3,254)`, wordSpacing: 200, text: 'VIP락카'},//여자

        {w: 155,  h: 8,   x: 120, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'G'},//가변구역
        {w: 155,  h: 8,   x: 310, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'F'},//가변구역
        {w: 155,  h: 8,   x: 500, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'E'},//가변구역
        {w: 155,  h: 8,   x: 695, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'D'},//가변구역
        {w: 155,  h: 8,   x: 890, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'C'},//가변구역
        {w: 155,  h: 8,   x: 1080, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'B'},//가변구역
        {w: 155,  h: 8,   x: 1270, y: 720, fontSize: '28px', color: `rgb(152, 147, 152)`, wordSpacing: 200, text: 'A'},//가변구역
//세로
        {w: 2,    h: 195, x: 98,   y: 423, backgroundColor: `rgba(34, 34, 34)`,},  //락커번호385   세로
        {w: 2,    h: 150, x: 98,   y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호593  세로
        {w: 2,    h: 198, x: 98,   y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //락커번호856  세로

        {w: 2,    h: 380, x: 290,  y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호183
        {w: 2,    h: 130, x: 290,  y: 445,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호384
        {w: 2,    h: 175, x: 290,  y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호592
        {w: 2,    h: 198, x: 290,  y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //락커번호843

        {w: 2,    h: 180, x: 482,  y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호182
        {w: 2,    h: 150, x: 482,  y: 247, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호256
        {w: 2,    h: 175, x: 482,  y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호371
        {w: 2,    h: 175, x: 482,  y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호578
        {w: 2,    h: 198, x: 482,  y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //825

        {w: 2,    h: 180, x: 674,  y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호166
        {w: 2,    h: 150, x: 674,  y: 247, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호253
        {w: 2,    h: 175, x: 674,  y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호357
        {w: 2,    h: 175, x: 674,  y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호562
        {w: 2,    h: 198, x: 674,  y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //808

        {w: 2,    h: 180, x: 866,  y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호152
        {w: 2,    h: 150, x: 866,  y: 247, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호239
        {w: 2,    h: 175, x: 866,  y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호341
        {w: 2,    h: 175, x: 866,  y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호548
        {w: 2,    h: 198, x: 866,  y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //790

        {w: 2,    h: 180, x: 1058, y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호136
        {w: 2,    h: 150, x: 1058, y: 247, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호277
        {w: 2,    h: 175, x: 1058, y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호325
        {w: 2,    h: 175, x: 1058, y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호532
        {w: 2,    h: 198, x: 1058, y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //773

        {w: 2,    h: 180, x: 1250, y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호122
        {w: 2,    h: 150, x: 1250, y: 247, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호213
        {w: 2,    h: 175, x: 1250, y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호325
        {w: 2,    h: 175, x: 1250, y: 644, backgroundColor: `rgba(119, 0, 255)`,}, //락커번호520
        {w: 2,    h: 198, x: 1250, y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //755

        {w: 2,    h: 175, x: 1395, y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호013

        {w: 2,    h: 378, x: 1442, y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호101
        {w: 2,    h: 175, x: 1442, y: 644, backgroundColor: `rgba(254, 3, 254)`,}, //락커번호505
        {w: 2,    h: 198, x: 1442, y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //락커번호738

        {w: 2,    h: 175, x: 1730, y: 445, backgroundColor: `rgba(34, 34, 34)`,}, //락커번호006
        {w: 2,    h: 175, x: 1730, y: 644, backgroundColor: `rgba(254, 3, 254)`,}, //락커번호707

        {w: 2,    h: 380, x: 1970, y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //락커번호25 마지막 테두리쪽
        {w: 2,    h: 198, x: 1970, y: 862, backgroundColor: `rgba(254, 3, 254)`,}, //715
//가로
        {w: 1680, h: 2,   x: 292,  y: 23,  backgroundColor: `rgba(34, 34, 34)`,}, //첫 검정가로선들
        {w: 300,  h: 2,   x: 1442, y: 400,  backgroundColor: `rgba(34, 34, 34)`,}, //050
        {w: 585,  h: 2,   x: 1395, y: 620,  backgroundColor: `rgba(34, 34, 34)`,}, //050

        {w: 185,  h: 3,   x: 100,  y: 630,  backgroundColor: `rgb(0,0,255)`,}, //393
        {w: 185,  h: 3,   x: 295,  y: 630,  backgroundColor: `rgb(0,0,255)`,}, //363
        {w: 185,  h: 3,   x: 485,  y: 630,  backgroundColor: `rgb(0,0,255)`,}, //350
        {w: 185,  h: 3,   x: 680,  y: 630,  backgroundColor: `rgb(0,0,255)`,}, //350
        {w: 185,  h: 3,   x: 870,  y: 630,  backgroundColor: `rgb(0,0,255)`,}, //333
        {w: 185,  h: 3,   x: 1065, y: 630,  backgroundColor: `rgb(0,0,255)`,}, //317
        {w: 185,  h: 3,   x: 1260, y: 630,  backgroundColor: `rgb(0,0,255)`,}, //301

        {w: 290,  h: 2,   x: 1442, y: 644,  backgroundColor: `rgb(254,3,254)`,}, //708

        {w: 185,  h: 3,   x: 100,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //333
        {w: 185,  h: 3,   x: 295,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //317
        {w: 185,  h: 3,   x: 485,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //301
        {w: 185,  h: 3,   x: 680,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //301
        {w: 185,  h: 3,   x: 870,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //301
        {w: 185,  h: 3,   x: 1065,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //301
        {w: 185,  h: 3,   x: 1260,  y: 822,  backgroundColor: `rgb(254,3,254)`,}, //301

        {w: 1870, h: 2,   x: 100,  y: 1060,  backgroundColor: `rgb(254,3,254)`,}, //마지막 가로
        // 백그라운드 칼라 적용
        // {w: 838, h: 802, x: 1060, y: 0, mw: 773, mh: 696, mx: 995, backgroundColor: `rgba(250, 244, 192, .5)`,},
        //우측 여백 공간 조성을 위함.
        {w: 10, h: 10, x: 2000, y: 1100, mx: 1758,},
      ];
    },
  },
  async mounted() {
    await this.$nextTick();
    this.lockerKeepVisitorList = [];
    const response = await getVisitCopy(getDateOfPrevious(this.bsnDate));
    response?.value?.map(value => {
      value?.visitInfoList?.map(visitor => {
        this.lockerKeepVisitorList.push(visitor);
      });
    });

    if (!(this.lockerKeepVisitorList.length > 0)) {
      this.isLockerKeepVisitorStatus = false;
    }
  },
  methods: {
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
          ? print.substr(0, 3)
          : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
          ? '#9764b6'
          : !["CHECKIN","ASSIGN","KEEP"].includes(board.lockerStatus) && board.vipFlag
              ? '#AAF0D1'
              : (commonCodesGetColorValue(
                  'LOCKER_STATUS',
                  board.lockerStatus === "KEEP" ? "RESVE" : board.lockerStatus,
              ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
          : null;
    },
    showLockerKeepVisitorStatus() {
      this.isLockerKeepVisitorStatus = true;
    }
  }
};
</script>