<template>
  <div class="section-header">
    <div class="header-left">
      <div class="header-title">락카정보</div>
      <div class="header-caption">[{{ numberWithCommas(totalSize) }}건]</div>
    </div>
    <div class="header-right">
      <ul class="header-button">
        <li class="save keyColor">
          <erp-button
              button-div="SAVE"
              :is-key-color="true"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="handleSaveLockerInformationClick"
          >
            저장
          </erp-button>
        </li>
        <li class="add">
          <erp-button
              button-div="SAVE"
              :disabled="isModifyMode"
              :is-icon-custom="true"
              :is-custom-shortcut-button="true"
              shortcut-key="calendarRegistration.shortcuts.addCalendar"
              :shortcut="{key: 'F3'}"
              :ignore="isPopupOpened"
              @click.native="handleAddLockerClick"
          >
            추가
          </erp-button>
        </li>
        <li class="delete">
          <erp-button
              button-div="DELETE"
              :disabled="isModifyMode"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="handleDeleteLockerClick"
          >
            삭제
          </erp-button>
        </li>
      </ul>
      <div style="float: left; padding: 3px 3px 3px 18px">
        <input-number
          width="80"
          v-model="postOrderUnit"
          :min="0"
          style="background-color: #fff; width: 80px"
        />
      </div>
      <ul class="header-button" style="margin-right: 140px">
        <li class="post-order-settings settings">
          <erp-button
              button-div="SAVE"
              :is-icon-custom="true"
              @click.native="handlePostOrderSettingsClick"
          >
            배치순번셋팅
          </erp-button>
        </li>
        <li class="post-order-reset reset">
          <erp-button
              button-div="SAVE"
              :is-icon-custom="true"
              @click.native="handlePostOrderResetClick"
          >
            배치순번초기화
          </erp-button>
        </li>
        <li class="print">
          <erp-button
              button-div="FILE"
              @click.native="handleExcelClick"
          >
            Excel
          </erp-button>
        </li>
      </ul>
      <div class="header-switch">
        <div class="title">
          멀티 편집모드
        </div>
        <div class="switch">
          <ejs-switch
            :checked="isModifyMode"
            @change="onModifySwitchChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import inputNumber from "@/components/common/InputNumber";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LockerDailyInformationHeader",
  mixins: [commonMixin],
  props: ["totalSize", "isPopupOpened", "isModifyMode"],
  components: {
    inputNumber,
    ErpButton,
  },
  data() {
    return {
      postOrderUnit: 5,
    };
  },
  methods: {
    numberWithCommas,
    handleAddLockerClick() {
      this.$emit("addLockerClick");
    },
    handleDeleteLockerClick() {
      this.$emit("deleteLockerClick");
    },
    handleSaveLockerInformationClick() {
      this.$emit("saveLockerInformationClick");
    },
    handlePostOrderSettingsClick() {
      this.$emit("postOrderSettingsClick", {
        postOrderUnit: this.postOrderUnit
      });
    },
    handlePostOrderResetClick() {
      this.$emit("postOrderResetClick");
    },
    handleExcelClick() {
      this.$emit("excelClick");
    },
    onModifySwitchChanged({checked}) {
      this.$emit("update:isModifyMode", checked);
    },
  },
};
</script>
