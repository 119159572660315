var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-body",attrs:{"id":"powder-wrapper"},on:{"mousedown":_vm.onMouseDown}},[(_vm.propsLoading)?_c('div',{staticClass:"loader"}):_vm._e(),_c('div',{staticClass:"powder-box",style:({ propsZoom: _vm.propsZoom })},[_vm._l((_vm.backInfo),function(item,index){return _c('div',{key:index,staticClass:"back-info",style:({
        width: ((_vm.propsIsFullscreen ? item.w : (item.mw || item.w)) + "px"),
        height: ((_vm.propsIsFullscreen ? item.h : (item.mh || item.h)) + "px"),
        left: ((_vm.propsIsFullscreen ? item.x : (item.mx || item.x)) + "px"),
        top: ((_vm.propsIsFullscreen ? item.y : (item.my || item.y)) + "px"),
        wordSpacing: item.wordSpacing,
        backgroundColor: item.backgroundColor,
      })},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})])}),_c('div',{staticClass:"powder"},_vm._l((_vm.propsLockerStatusList),function(data){return _c('div',{key:((data.sector) + "_" + (data.lockerDiv)),class:("sector sector" + (data.sector))},_vm._l((data.lockerBoardList),function(board){return _c('div',{key:board.lockerNo,class:[
                        'locker',
                        ("locker" + (board.lockerNo)),
                        _vm.propsSearchValue === board.lockerPrtNo || _vm.propsSearchValue === _vm.getResizingPrintLabel(board.lockerPrtNo)
                          ? 'search rainbow'
                          : undefined,
                        isNaN(board.lockerPrtNo) && _vm.propsViewDiv === 'VISIT'
                          ? 'clear'
                          : undefined ],style:(Object.assign({}, {left: ((Math.floor((_vm.propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (_vm.propsIsFullscreen ? 35 : 32)) + "px"),
                        top: ((Math.floor((_vm.propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (_vm.propsIsFullscreen ? 22 : 19)) + "px")},
                        _vm.getColorValue(board))),on:{"click":function($event){return _vm.openLockerKeyAssignPopup(board.lockerNo)}}},[_vm._v(" "+_vm._s(_vm.getResizingPrintLabel(board.lockerPrtNo))+" ")])}),0)}),0)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }