<template>
  <div>
    <ejs-dialog
      ref="lockerKeyAssignPopup"
      :header="`락카키 배정`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="600"
      height="325"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onLockerKeyAssignPopupClosed"
    >
      <div class="window lockerKeyAssign">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article" style="padding: 9px 9px 0px 9px;">
                <section class="article-section">
                  <div class="section-body" style="border-top-color: #000">
                    <div
                      style="
                        line-height: 23px;
                        height: 23px;
                        box-sizing: border-box;
                        color: #000;
                        background-color: #f9f9f9;
                        text-align: center;
                      "
                    >
                      락카키 :
                      {{
                        lockerBoard.lockerDiv === "M"
                          ? "남"
                          : lockerBoard.lockerDiv === "F"
                          ? "여"
                          : "가변"
                      }}
                      - {{ lockerBoard.sectorPrt }}구역
                    </div>
                    <div
                      style="
                        border-top: 1px solid #e0e0e0;
                        line-height: 52px;
                        height: 53px;
                        box-sizing: border-box;
                        color: #000;
                        text-align: center;
                        font-size: 20px;
                        font-family: 'NanumSquare-Bold', Arial, Verdana,
                          sans-serif;
                      "
                    >
                      {{ lockerBoard.lockerPrtNo }}
                    </div>
                    <div>
                      <div style="float: left; width: 12%; box-sizing: border-box">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          시간
                        </div>
                      </div>
                      <div style="float: left; width: 12%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          ID번호
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          내장자
                        </div>
                      </div>
                      <div style="float: left; width: 25%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          체크인시간
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          체크아웃여부
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; color: #000; background-color: #f9f9f9; padding: 0 6px; text-align: center;">
                          락카반납여부
                        </div>
                      </div>
                      <div style="clear: both; height: 0"></div>
                    </div>
                    <div class="locker-bottom-line"
                         v-for="(data, idx) in lockerBoardVisitInfos" :key="idx">
                      <div style="float: left; width: 12%; box-sizing: border-box">
                        <div style=" border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; padding: 0 6px; text-align: center;">
                          {{ data.startTime }}
                        </div>
                      </div>
                      <div style="float: left; width: 12%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; padding: 0 6px; text-align: center;">
                          {{ data.visitId }}
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; text-align: center;">
                          {{ data.visitName }}
                        </div>
                      </div>
                      <div style="float: left; width: 25%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; text-align: center;">
                          {{ data.visitCmpnInsertDt }}
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; text-align: center;">
                          <input type="checkbox" v-model="data.teamChkoutFlag" :disabled="true"/>
                        </div>
                      </div>
                      <div style="float: left; width: 17%; box-sizing: border-box; border-left: 1px solid #e0e0e0;">
                        <div style="border-top: 1px solid #e0e0e0; line-height: 23px; height: 24px; box-sizing: border-box; text-align: center;">
                          <input type="checkbox" v-model="data.lockerReturnFlag" :disabled="true"/>
                        </div>
                      </div>
                      <div style="clear: both; height: 0"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
            <!-- 지정락카 INFO -->
            <div class="" v-if="lockerBoard.lockerStatus === 'FIX'|| lockerBoard.lockerStatus === 'EMPTY'" style="margin-top: -4px">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <div class="label" style="padding-left: 1.8em">지정락카</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              {{ lockerBoard.appnLocker }} {{telNumberValueAccess(this.lockerBoard.appnHpNo)}}
                            </label>
                          </li>
                        </ul>
                      </li>
                      <li class="item" v-if="lockerBoard.lockerStatus === 'EMPTY'">
                        <ul class="button">
                          <li class="search">
                            <erp-button button-div="GET" @click.native="memberSelectPopupOpen()">검색</erp-button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

<!--                  <li class="field" v-if="lockerBoard.lockerStatus === 'EMPTY1'">
                    <ul class="content">
                      <li class="item">
                        <div class="form">
                          <input-text ref="appnLocker" v-model="lockerBoard.appnLocker" :readonly="true" style="width: 100px; pointer-events: none;" />
                        </div>
                      </li>
                      <li class="item">
                        <ul class="button">
                          <li class="search">
                            <erp-button button-div="GET" @click.native="memberSelectPopupOpen()">검색</erp-button>
                          </li>
                        </ul>
                      </li>
                      <li class="item">
                        <div class="form">
                          [{{telNumberValueAccess(this.lockerBoard.appnHpNo)}}]
                        </div>
                      </li>
                    </ul>
                  </li>-->

                </ul>
              </div>
            </div>

            <div class="content-lookup" style="padding: 1px 9px">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="batchOverlap.flag"
                                @change="onBatchOverlapFlagClicked"
                              />
                              <i></i>
                              <div class="label">일괄중복</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item input-01 batchNumber">
                        <div class="form">
                          <input-text
                            ref="lockerNoFrom"
                            v-model="batchOverlap.lockerNoFrom"
                            :disabled="!batchOverlap.flag"
                            :digit-only="true"
                          />
                        </div>
                        <div class="validation">
                          <div class="e-tip-content"></div>
                          <div class="e-tip-top"></div>
                        </div>
                      </li>
                      <li class="item dash">~</li>
                      <li class="item input-02 batchNumber">
                        <div class="form">
                          <input-text
                            ref="lockerNoTo"
                            v-model="batchOverlap.lockerNoTo"
                            :disabled="!batchOverlap.flag"
                            :digit-only="true"
                          />
                        </div>
                        <div class="validation">
                          <div class="e-tip-content"></div>
                          <div class="e-tip-top"></div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="lookup-right">
                <ul class="lookup-button">
                  <li class="keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-icon-custom="true"
                        @click.native="onBatchOverlapApplyButtonClicked('DUP')"
                    >
                      일괄적용
                    </erp-button>
                  </li>
                  <li class="cancel">
                    <erp-button
                        button-div="DELETE"
                        :is-icon-custom="true"
                        @click.native="onBatchOverlapApplyButtonClicked('EMPTY')"
                    >
                      일괄취소
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-left">
            <ul class="lookup-condition"
                v-if= "lockerBoard.lockerStatus !== 'FIX' &&
                  lockerBoard.lockerStatus !== 'OUTOF' &&
                  lockerBoard.lockerStatus !== 'ASSIGN' &&
                  lockerBoard.lockerStatus !== 'CHECKIN'"
                v-show="this.fromFrontCheckinVisitPopupOpened"
            >
              <li class="field">
                <ul class="content">
                  <li class="item">
                    <ul class="check">
                      <li>
                        <label>
                          <div class="label">이름</div>
                        </label>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item input">
                        <div class="form">
                          <input-text
                              ref="name"
                              v-model="name"
                          />
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="lookup-right">
            <ul class="lookup-button">
            <li
              class="confirm keyColor"
              v-if="lockerBoard.lockerStatus !== 'ASSIGN'"
            >
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  :shortcut="{key:'Enter'}"
                  :shortcut-key="'LockerKeyAssignPopup.shortcut.save'"
                  :disabled="
                  lockerBoard.lockerStatus === 'FIX' ||
                  lockerBoard.lockerStatus === 'OUTOF' ||
                  lockerBoard.lockerStatus === 'CHECKIN' ||
                  isMemberSelectPopupOpen
                "
                  :ignore="
                  lockerBoard.lockerStatus === 'FIX' ||
                  lockerBoard.lockerStatus === 'OUTOF' ||
                  lockerBoard.lockerStatus === 'CHECKIN' ||
                  isMemberSelectPopupOpen
                "
                  @click.native="putKeyAssign('ASSIGN')"
              >
                배정
              </erp-button>
            </li>
            <li class="cancel" v-else>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="putKeyAssign('EMPTY')"
              >
                취소
              </erp-button>
            </li>
            <li v-if="lockerBoard.lockerStatus !== 'FIX'">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :disabled="
                  lockerBoard.lockerStatus === 'CHECKIN' ||
                  lockerBoard.lockerStatus === 'DUP'
                "
                  @click.native="putKeyAssign('FIX')"
              >
                지정
              </erp-button>
            </li>
            <li v-else>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="putKeyAssign('FIXCANCEL')"
              >
                지정해제
              </erp-button>
            </li>
            <li v-if="lockerBoard.lockerStatus !== 'OUTOF'">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :disabled="
                  lockerBoard.lockerStatus === 'CHECKIN' ||
                  lockerBoard.lockerStatus === 'DUP'
                "
                  @click.native="putKeyAssign('OUTOF')"
              >
                고장
              </erp-button>
            </li>
            <li v-else>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="putKeyAssign('EMPTY')"
              >
                고장해제
              </erp-button>
            </li>
            <li v-if="lockerBoard.lockerStatus !== 'DUP' && lockerBoard.orgLockerStatus !== 'DUP'">
              <erp-button
                  button-div="SAVE"
                  :disabled="
                  lockerBoard.lockerStatus === 'FIX' ||
                  lockerBoard.lockerStatus === 'OUTOF'
                "
                  :is-icon-custom="true"
                  @click.native="putKeyAssign('DUP')"
              >
                중복
              </erp-button>
            </li>
            <li v-else>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="putKeyAssign('EMPTY')"
              >
                중복해제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onLockerKeyAssignPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </ejs-dialog>

    <member-select-popup
        v-if="isMemberSelectPopupOpen"
        ref="memberSelectPopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClosed="memberSelectPopupClosed"
        @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
.locker-bottom-line:last-child {border-bottom: 1px solid rgb(224, 224, 224);}
body .lookup-condition .field .content .item.batchNumber {width: 50px;}
body .window.lockerKeyAssign .section-body {overflow-y: scroll;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputText from "@/components/common/text/InputText";
import { validateFormRefs } from "@/utils/formUtil";

import { getLockerKeyInfo, putLockerAssign } from "@/api/frontManagement";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import {commonCodesGetComName} from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import moment from "moment";
import memberSelectPopup from "@/views/common/MemberSelectPopup.vue";
import {getFormattedPhoneNumber} from "@/utils/string";

export default {
  name: "lockerKeyAssignPopup",
  components: {
    memberSelectPopup,
    InputText,
    ErpButton
  },
  props: {
    fromFrontCheckinVisitPopupOpened: Boolean,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      bsnDate: null,
      lockerNo: null,
      lockerDiv: null,
      lockerBoard: {
        lockerNo: null,
        lockerDiv: null,
        sector: null,
        sectorPrt: null,
        lockerPrtNo: null,
        resveName: null,
        resveId: null,
        lockerStatus: null,
        appnLocker : null,
        appnMemberId : null,
        appnHpNo : null,
      },
      lockerBoardVisitInfos: [],
      isAuto: false,
      isVisitLockerNo: false,
      batchOverlap: {
        flag: false,
        lockerNoFrom: null,
        lockerNoTo: null,
      },
      name: null,
      batchOverlapValidationRules: {
        lockerNoFrom: {
          required: true,
        },
        lockerNoTo: {
          required: true,
        },
      },
      isMemberSelectPopupOpen : false,
    };
  },
  computed: {
    lockerAssignShortcutButtonProps() {
      return {
        shortcutKey: "LockerKeyAssingPopup.shortcuts.lockerAssign",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.Enter,
        },
      };
    },
  },
  methods: {
    validateFormRefs,
    async showLockerKeyAssignPopup(
      bsnDate,
      lockerNo,
      lockerDiv,
      isVisitLockerNo = false
    ) {
      this.bsnDate = bsnDate;
      this.lockerNo = lockerNo;
      this.lockerDiv = lockerDiv;
      this.isVisitLockerNo = isVisitLockerNo;
      if (lockerDiv) {
        await this.fetchLockerDataAuto(bsnDate, lockerDiv);
      } else {
        await this.fetchLockerData(bsnDate, lockerNo);
      }
    },
    onLockerKeyAssignPopupClosed() {
      this.$emit("popupClosed", { isRefresh: false });
    },
    async fetchLockerDataAuto(bsnDate, lockerDiv) {
      // 락카 키 자동 발행
      // (자동 발행시 isStatusUpdate = true 이면 락카 상태까지 배정으로 UPDATE)
      // (isStatusUpdate = true 이면서 이전 락카번호까지 넘겨주면 이전 락카번호는 빈락카 상태로 UPDATE)
      const autoLockerKeyInfoResponse = await GolfErpAPI.fetchAutoLockerBoard({
        bsnDate: bsnDate,
        lockerDiv: lockerDiv,
        isStatusUpdate: false,
      });

      const lockerBoard = autoLockerKeyInfoResponse.lockerBoard;
      const lockerBoardVisitInfos =
        autoLockerKeyInfoResponse.lockerBoardVisitInfos;

      if (lockerBoard === null) {
        this.errorToast("배정할 번호가 없습니다");
        this.onLockerKeyAssignPopupClosed();
        return;
      }

      this.lockerBoard = lockerBoard;
      this.lockerBoardVisitInfos = lockerBoardVisitInfos;
      this.lockerNo = lockerBoard.lockerNo;
    },
    async fetchLockerData(bsnDate, lockerNo) {
      const {
        value: { lockerBoard, lockerBoardVisitInfos },
      } = await getLockerKeyInfo(bsnDate, lockerNo);
      this.lockerBoard = lockerBoard;
      this.lockerBoardVisitInfos = lockerBoardVisitInfos;
    },
    async putKeyAssign(status) {
      if (status === "ASSIGN") {
        const { value: newLockerInfo } = await getLockerKeyInfo(
          this.bsnDate,
          this.lockerNo,
          true
        );
        if (newLockerInfo !== null && newLockerInfo.lockerBoard !== null) {
          if (
            // 락카키 배정 팝업에서는 VIP 상태의 락카도 배정할 수 없음.
            // VIP 상태의 락카는 체크인 내장객 등록 화면에서만 배정 가능.
            newLockerInfo.lockerBoard.lockerStatus !== "EMPTY"
            // &&
            // newLockerInfo.lockerBoard.lockerStatus !== "FIX"
          ) {
            this.errorToast("이미 사용중인 락카 번호입니다");
            return;
          }
        }
      }

      let lockerStatus = JSON.parse(JSON.stringify(status));

      //지정상태에서 지정자 이름없을 경우 "-" 문자열 넣어준다.( 향후 임의지정 등으로 변경가능있음)
      //지정락카는 지정자가 필수가 되도록 한다.
      if (status === "FIX" ) {
        this.lockerBoard.appnLocker = (this.lockerBoard.appnLocker)?.trim() || "-";
      }

      if (status === "ASSIGN" && this.lockerBoard.lockerStatus === "FIX") {
        lockerStatus = "FIX";
      }

      await putLockerAssign(lockerStatus, this.lockerBoard);

      if (status === "ASSIGN" && !this.isVisitLockerNo) {
        await this.onLockerIssue({
          visitDate: this.bsnDate,
          lockerNo: this.lockerNo,
          lockerPrtNo: this.lockerBoard.lockerPrtNo,
          sector: this.lockerBoard.sector,
          sectorPrt: this.lockerBoard.lockerDiv === "M" ? this.lockerBoard.sectorPrtMale : this.lockerBoard.sectorPrtFemale,
          sexCode: this.lockerBoard.lockerDiv
        });
      }

      this.$emit("popupClosed", {
        isRefresh: true,
        keyAssignStatus: status,
        setLockerNo: this.lockerNo,
        lockerPrtNo: this.lockerBoard.lockerPrtNo,
        lockerDiv: this.lockerBoard.lockerDiv,
        sector: this.lockerBoard.sector,
        sectorPrtMale: this.lockerBoard.sectorPrtMale,
        sectorPrtFemale: this.lockerBoard.sectorPrtFemale,
      });

      // await putLockerAssign(lockerStatus, this.lockerBoard).then(async (result) => {
      //   // await this.fetchLockerData(this.bsnDate, this.lockerNo);
      //   this.$emit("popupClosed", {
      //     isRefresh: true,
      //     keyAssignStatus: status,
      //     setLockerNo: this.lockerNo,
      //     lockerDiv: this.lockerBoard.lockerDiv
      //   });
      // });
    },
    async onLockerIssue(lockerIssueInfo) {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      const printSetData = {
        visitId: null,
        visitDate: lockerIssueInfo.visitDate,
        startCourse: null,
        startTime: null,
        sector: lockerIssueInfo.sector,
        sectorPrt: lockerIssueInfo.sectorPrt,
        lockerNo: lockerIssueInfo.lockerPrtNo,
        sexName: commonCodesGetComName("SEX_CODE", lockerIssueInfo.sexCode),
        visitName: this.name,
        contactTel: null,
        dwName: moment(lockerIssueInfo.visitDate).format("dd"),
      };

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this,"LOCKER_KEY",true,printSetData,config);

    },
    onBatchOverlapFlagClicked() {
      if (!this.batchOverlap.flag) {
        this.batchOverlap.lockerNoFrom = null;
        this.batchOverlap.lockerNoTo = null;
      }
    },
    async onBatchOverlapApplyButtonClicked(lockerStatus) {
      if (!this.batchOverlap.flag) {
        return;
      }

      if (!this.validateFormRefs(this.batchOverlapValidationRules)) {
        return;
      }

      const data = {
        bsnDate: this.bsnDate,
        lockerStatus: lockerStatus,
        lockerNoFrom: this.batchOverlap.lockerNoFrom,
        lockerNoTo: this.batchOverlap.lockerNoTo,
      };

      await GolfErpAPI.putLockerDailyStatusByBsnDateAndLockerNoBetween(data);

      this.$emit("popupClosed", { isRefresh: true });
    },

    //지정락커 사용자 지정 팝업
    memberSelectPopupOpen(e){
      this.isMemberSelectPopupOpen = true;
      const  memberData = {
        isOpenedWithButton: true,
        popupType: "VISIT_MEMBER",
        memberNameNo: this.lockerBoard.appnLocker,
        selectedIdx: 0,
        focusGridRowColumn: null,
        memberGradeChanged: false,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed({popupData,selectedRowData}) {
      this.isMemberSelectPopupOpen = false;
      //data 세팅
      this.lockerBoard.appnLocker = selectedRowData.memberName;
      this.lockerBoard.appnMemberId = selectedRowData.memberId;
      this.lockerBoard.appnHpNo = selectedRowData.hpNo;
    },
    telNumberValueAccess(tel) {
      return tel != null ? "(" + getFormattedPhoneNumber(this.lockerBoard.appnHpNo) +")": "";
    },
  },

};
</script>
