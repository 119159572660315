<template>
  <div id="locker-status-out-of">
    <ejs-dialog
      ref="locker-status-out-of-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :header="`락카 상태 설정 [선택 ${targetLockersCount}개]`"
      @close="handleCloseDialog"
    >
      <div class="window lockerBroken">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">비고사항</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea v-model="remarks" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="handleSubmitClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SetLockerStatusOutOfPopup",
  components: {
    InputTextarea,
    ErpButton,
  },
  mixins: [commonMixin],
  props: ["targetLockersCount"],
  data() {
    return {
      remarks: "",
    };
  },
  methods: {
    hideDialog() {
      this.$refs["locker-status-out-of-dialog"].hide();
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    handleSubmitClick() {
      this.hideDialog();
      this.$emit("submit", {
        remarks: this.remarks,
      });
    },
    handleCloseClick() {
      this.hideDialog();
    },
  },
};
</script>

<style scoped></style>
