<template>
  <div
    id="powder-wrapper"
    class="content-body"
    @mousedown="onMouseDown"
  >
    <div
      v-if="propsLoading"
      class="loader"
    />
    <div class="powder-box" :style="{ propsZoom }">
      <div
        v-for="(item, index) in backInfo"
        :key="index"
        class="back-info"
        :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing,
          backgroundColor: item.backgroundColor,
        }"
      >
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
          v-for="data in propsLockerStatusList"
          :key="`${data.sector}_${data.lockerDiv}`"
          :class="`sector sector${data.sector}`"
        >
          <div
            v-for="board in data.lockerBoardList"
            :key="board.lockerNo"
            :class="[
                          'locker',
                          `locker${board.lockerNo}`,
                          propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                            ? 'search rainbow'
                            : undefined,
                          isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                            ? 'clear'
                            : undefined,
                        ]"
            :style="{
                          left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 35 : 32)}px`,
                          top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
                          ...getColorValue(board),
                        }"
            @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 31px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";

export default {
  name: "LockerStatusJ01",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom", "propsLockerStatusList", "propsSearchValue"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {},
  data() {
    return {
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        1,
        false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        2,
        false,
      ),
    };
  },
  computed: {
    backInfo() {
      return [
        {
          w: 593,
          h: 68,
          x: 5,
          y: 25,
          mw: 545,
          mh: 37,
          text: '여 자',
          wordSpacing: 200,
        },
        {
          w: 593,
          h: 68,
          x: 5,
          y: 135,
          mw: 545,
          mh: 37,
          my: 101,
          text: '락 카',
          wordSpacing: 200,
        },
        {
          w: 283,
          h: 916,
          x: 650,
          y: 0,
          mw: 265,
          mh: 682,
          mx: 593,
          backgroundColor: `rgba(250, 244, 192, .5)`,
        },
        {
          w: 243,
          h: 68,
          x: 670,
          y: 25,
          mw: 225,
          mh: 37,
          mx: 613,
          text: '가',
        },
        {
          w: 243,
          h: 68,
          x: 670,
          y: 135,
          mw: 225,
          mh: 37,
          mx: 613,
          my: 101,
          text: '변',
        },
        {
          w: 243,
          h: 68,
          x: 670,
          y: 245,
          mw: 225,
          mh: 37,
          mx: 613,
          my: 177,
          text: '락',
        },
        {
          w: 243,
          h: 68,
          x: 670,
          y: 355,
          mw: 225,
          mh: 37,
          mx: 613,
          my: 253,
          text: '카',
        },
        // 최소화시 우측 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 1888,
          y: 0,
          mx: 1758,
        },
        {
          w: 908,
          h: 68,
          x: 985,
          y: 25,
          mw: 833,
          mh: 37,
          mx: 901,
          text: '남 자',
          wordSpacing: 200,
        },
        {
          w: 908,
          h: 68,
          x: 985,
          y: 135,
          mw: 833,
          mh: 37,
          mx: 901,
          my: 101,
          text: '락 카',
          wordSpacing: 200,
        },
        {
          w: 313,
          h: 220,
          x: 5,
          y: 335,
          mw: 289,
          my: 236,
          text: `여자<br>락카<br>화장실`,
          backgroundColor: `rgba(0, 0, 0, .1)`,
        },
        {
          w: 243,
          h: 110,
          x: 985,
          y: 445,
          mw: 225,
          mx: 901,
          my: 312,
          text: `남자락카<br>화장실`,
          backgroundColor: `rgba(0, 0, 0, .1)`,
        },
        {
          w: 123,
          h: 110,
          x: 985,
          y: 555,
          mw: 113,
          mx: 901,
          my: 422,
          backgroundColor: `rgba(0, 0, 0, .1)`,
        },
      ];
    },
  },
  methods: {
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
        ? print.substr(0, 3)
        : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.vipFlag
        ? '#AAF0D1'
        : board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
          ? '#9764b6'
          : (commonCodesGetColorValue(
            'LOCKER_STATUS',
            board.lockerStatus,
          ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    },
  }
};
</script>