import request from "@/utils/request";

/**
 * 내장긱현황 타임 List 조회
 * @param bsnDate
 * @param courseCode
 * @param partDiv
 * @return Promise
 */
export function getVisitTimeList(bsnDate, courseCode, partDiv, isMemoMode= true) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/visit/time-list",
    method: "get",
    params: {
      bsnDate: bsnDate,
      courseCode: courseCode,
      partDiv: partDiv,
      isMemoMode
    },
  });
}

export function getVisitTestTimeList(bsnDate, courseCode, partDiv, isMemoMode= true) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/visit/time-list/two",
    method: "get",
    params: {
      bsnDate: bsnDate,
      courseCode: courseCode,
      partDiv: partDiv,
      isMemoMode
    },
  });
}


/**
 * 예약자명,내장객명 (예약자명 동명이인 팝업 조회) 조회
 * @return Promise
 */
export function getFindVisitList(
  bsnDate,
  courseCode,
  partDiv,
  resveVisitName,
  resveVisitFlag
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/visit/find-visit-list",
    method: "get",
    params: {
      bsnDate,
      courseCode,
      partDiv,
      resveVisitName,
      resveVisitFlag,
    },
  });
}

/**
 * 타임List의 보관 항목 저장
 * @param chkinId
 * @param kepFlag
 * @param optFlag
 * @return Promise
 */
export function putVisitTimeList(chkinId, kepFlag, optFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/visit/time-list/${chkinId}`,
    method: "put",
    data: {
      visitTimeInfo: {
        kepFlag: kepFlag,
        optFlag: optFlag,
      },
    },
  });
}

/**
 * 일일종합현황 조회
 * @param bsnDate
 * @return Promise
 */
export function getDailyStatus(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/daily-status/${bsnDate}`,
    method: "get",
  });
}

/**
 * 단체팀 설정
 * @param grpStatus
 * @param data
 * @return Promise
 */
export function groupConnect(grpStatus, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/visit/group/${grpStatus}`,
    method: "put",
    data: data,
  });
}

/**
 * 악천후 설정
 * @param cancelStatus
 * @param data
 * @return Promise
 */
export function rainCancel(cancelStatus, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/visit/rainCancel/${cancelStatus}`,
    method: "put",
    data: data,
  });
}

/**
 * 갤러리 등록
 * @param bsnDate
 * @param data
 * @return Promise
 */
export function saveGallery(bsnDate, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/visit/gallery/${bsnDate}`,
    method: "post",
    data: data,
  });
}

/**
 * 락카 상태 저장
 * @return Promise
 */
export function lockerStatusSetting(lockerStatus, data, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/locker/${lockerStatus}`,
    method: "put",
    isBackground: isBackground,
    data: data,
  });
}

/**
 * 지불자 변경 팝업의 내장객 리스트 조회
 * @return Promise
 */
export function getPayerChange(bsnDate, chkinId, grpNo, lockerNo, visitName) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/payer-change`,
    method: "get",
    params: {
      bsnDate,
      chkinId,
      grpNo,
      lockerNo,
      visitName,
    },
  });
}

/**
 * 지불자변경 저장
 * @return Promise
 */
export function putPayerChange(slipId, visitId, optFlag, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/payer-change/${slipId}/${visitId}/${optFlag}`,
    method: "put",
    data: data,
  });
}

/**
 * 정산자변경 저장
 * @return Promise
 */
export function putPayerChange2(payId, visitId, bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/payer-change2/${payId}/${visitId}/${bsnDate}`,
    method: "put",
    data: null,
  });
}

/**
 * @return Promise
 */
export function getLockerStatusList(bsnDate, lockerDiv, viewDiv) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/lockers`,
    method: "get",
    params: {
      bsnDate,
      lockerDiv,
      viewDiv,
    },
  });
}

/**
 * @return Promise
 */
export function putInitLockerStatus(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/locker/init/${bsnDate}`,
    method: "put",
    data: null,
  });
}

/**
 * @return Promise
 */
export function getLockerKeyInfo(bsnDate, lockerNo, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/lockers/${lockerNo}`,
    method: "get",
    isBackground: isBackground,
    params: {
      bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function putLockerAssign(lockerStatus, body) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/locker/${lockerStatus}`,
    method: "put",
    data: body,
  });
}

/**
 * @return Promise
 */
export function getCartDiscountList(bsnDate, chkinId, grpNo, teamGroupDiv) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/cart-dc-list`,
    method: "get",
    params: {
      bsnDate,
      chkinId,
      grpNo,
      teamGroupDiv,
    },
  });
}

/**
 * @return Promise
 */
export function putCartDiscountList(bsnDate, chkinId, grpNo, cartDcList) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/check-in/cart-dc-list",
    method: "put",
    data: {
      bsnDate,
      chkinId,
      grpNo,
      cartDcList,
    },
  });

}
/**
 * 타임List의 키오스크사용여부 저장
 * @param chkinId
 * @param kepFlag
 * @param optFlag
 * @return Promise
 */
export function putKioskSet(resveId, kioskUseFlag, optFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/visit/kiosk`,
    method: "put",
    data: {
        resveId: resveId,
        kioskUseFlag: kioskUseFlag,
        resveConfirmOptFlag: optFlag,
    },
  });
}



/**
 * 내장팀 이동용 예약 리스트 조회
 * @return Promise
 */
export function getMoveTeam(paramObj) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/move-team`,
    method: "get",
    params: {
     ...paramObj
    },
  });
}

/**
 * 내장팀 이동 저장
 * @return Promise
 */
export function putMoveTeam(chkinId, resveId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/check-in/move-team/${chkinId}/${resveId}`,
    method: "put",
    data: null,
  });
}




