<template>
  <div
    id="powder-wrapper"
    class="content-body"
    @mousedown="onMouseDown"
  >
    <div
      v-if="propsLoading"
      class="loader"
    />
    <div class="powder-box" :style="{ propsZoom }">
      <div
        v-for="(item, index) in backInfo"
        :key="index"
        class="back-info"
        :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing,
          backgroundColor: item.backgroundColor,
          color: item.color,
          fontSize: `${propsIsFullscreen ? item.fontSize : (item.mFontSize || item.fontSize)}`,
        }"
      >
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
          v-for="data in propsLockerStatusList"
          :key="`${data.sector}_${data.lockerDiv}`"
          :class="`sector sector${data.sector}`"
        >
          <div
            v-for="board in data.lockerBoardList"
            :key="board.lockerNo"
            :class="[
              'locker',
              `locker${board.lockerNo}`,
              propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                ? 'search rainbow'
                : undefined,
              isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                ? 'clear'
                : undefined,
            ]"
            :style="{
              left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 48 : 45)}px`,
              top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
              ...getColorValue(board),
            }"
            @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
      <div
        v-show="isLockerKeepVisitorStatus"
        style="position: absolute; left: 1230px; top: 523px; width: 260px; height: 267px; border: 0px solid;"
      >
        <div
          class="div-header"
          style="background-color: #2e7458; line-height: 35px; border-radius: 1px 1px 0 0;"
        >
          <erp-button
            button-div="CLOSE"
            :use-syncfusion-component-style="false"
            style="cursor: pointer; left: 0; width: 33px; height: 35px; padding: 0; background: transparent; border-color: transparent; border-radius: 50%; bottom: 0; float: right;position: relative; box-shadow: none; display: inline-block; justify-content: center; vertical-align: middle; white-space: nowrap;"
            @click="isLockerKeepVisitorStatus = !isLockerKeepVisitorStatus"
          >
            <span
              style="width: 100%; height: 100%; margin: 0; opacity: 0.5; font-size: 12px; display: inline-block; vertical-align: middle; background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMzMTRFQjU5QkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMzMTRFQjVBQkIxMjExRTk5MDI1QjBEQkRFMjk3QUU2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzMxNEVCNTdCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzMxNEVCNThCQjEyMTFFOTkwMjVCMERCREUyOTdBRTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz520KoJAAAAQ0lEQVR42nTOUQ4AMAQDUHr/O3dbIgtN+cMTTd6KiIy9iALcwNujGgf/B7RhhyMC5DoVOET3Gi6DQrgMCrGAAY8AAwDe6xQOLXhdxAAAAABJRU5ErkJggg==) no-repeat center center;"
            ></span>
          </erp-button>
          <div
            class="header-title"
            style="padding: 0 0 0 12px; color: #fff; font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif; font-weight: normal; font-size: 16px; width: calc(100% - 63px);"
          >
            전일 락카보관 현황
          </div>
        </div>
        <div
          class="body-grid"
          style="height: calc(100% - 24px);"
        >
          <ejs-grid-wrapper
            :provides="grid"
            :allowResizing="false"
            :allowSorting="false"
            :allowFiltering="false"
            :allowEditing="false"
            :dataSource="lockerKeepVisitorList"
            :columns="lockerKeepVisitorGridColumns"
            style="height: 100%;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 44px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Resize,
  ForeignKey,
  Aggregate,
  Filter,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import {getVisitCopy} from "@/api/frontManagementCheckin";
import {getDateOfPrevious} from "@/utils/date";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "LockerStatusE01",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom", "propsLockerStatusList", "propsSearchValue", "bsnDate"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      isLockerKeepVisitorStatus: true,
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      lockerKeepVisitorGridColumns: [
        {
          field: "lockerNo",
          headerText: "락카",
          width: "60",
          textAlign: "center",
        },
        {
          field: "visitName",
          headerText: "내장객명",
          width: "70",
        },
        {
          field: "sexCode",
          headerText: "성별",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "SEX_CODE",
        }
      ],
      lockerKeepVisitorList: [],
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        1,
        false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        2,
        false,
      ),
    };
  },
  computed: {
    backInfo() {
      return [
        {
          w: 1055,
          h: 68,
          x: 5,
          y: -10,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: '남 자 락 카',
          wordSpacing: 200,
        },
        {
          w: 838,
          h: 802,
          x: 1060,
          y: 0,
          mw: 773,
          mh: 696,
          mx: 995,
          backgroundColor: `rgba(250, 244, 192, .5)`,
        },
        {
          w: 838,
          h: 68,
          x: 1060,
          y: -10,
          mw: 773,
          mh: 37,
          mx: 995,
          my: 5,
          text: '가 변 락 카',
        },
        // 최소화시 우측 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 1888,
          y: 0,
          mx: 1758,
        },
        {
          w: 1060,
          h: 382,
          x: 0,
          y: 420,
          mw: 995,
          mh: 330,
          mx: 0,
          my: 366,
          backgroundColor: `rgba(245, 245, 245)`,
        },
        {
          w: 50,
          h: 200,
          x: 170,
          y: 510,
          mw: 50,
          mh: 200,
          mx: 0,
          my: 430,
          text: '여<br>자<br>락<br>카',
          wordSpacing: 200,
        },
        {
          w: 838,
          h: 24,
          x: 1060,
          y: 311,
          mw: 773,
          mh: 18,
          mx: 995,
          my: 272,
          text: "가 벽",
          wordSpacing: "100px",
          backgroundColor: `rgba(34, 34, 34)`,
          color: `rgb(255,255,255)`,
          fontSize: "20px",
          mFontSize: "16px",
        },
      ];
    },
  },
  async mounted() {
    await this.$nextTick();
    this.lockerKeepVisitorList = [];
    const response = await getVisitCopy(getDateOfPrevious(this.bsnDate));
    response?.value?.map(value => {
      value?.visitInfoList?.map(visitor => {
        this.lockerKeepVisitorList.push(visitor);
      });
    });

    if (!(this.lockerKeepVisitorList.length > 0)) {
      this.isLockerKeepVisitorStatus = false;
    }
  },
  methods: {
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
        ? print.substr(0, 3)
        : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
        ? '#9764b6'
        : !["CHECKIN","ASSIGN","KEEP"].includes(board.lockerStatus) && board.vipFlag
          ? '#AAF0D1'
          : (commonCodesGetColorValue(
          'LOCKER_STATUS',
          board.lockerStatus === "KEEP" ? "RESVE" : board.lockerStatus,
            ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    },
    showLockerKeepVisitorStatus() {
      this.isLockerKeepVisitorStatus = true;
    }
  }
};
</script>