<template>
  <div id="locker-check-in-status">
    <ejs-dialog
      width="249"
      ref="locker-check-in-status-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :header="'락카 체크인 현황'"
      @close="handleCloseDialog"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <ejs-grid-wrapper
              ref="lockerCheckInStatusListGrid"
              :allowResizing="true"
              :dataSource="lockerCheckInStatusList"
              :selectionSettings="selectionSettings"
              :columns="lockerCheckInStatusListGridColumn"
              :provides="grid"
            />
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="handleSubmitClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { getLockerCheckInStatus } from "@/api/lockerInfoList";
import { commonCodesGetComName } from "@/utils/commonCodes";
import {
  Aggregate,
  Filter,
  ForeignKey,
  Group,
  Resize,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LockerCheckInStatusPopup",
  props: ["bsnDate", "lockerNo"],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  async created() {
    await this.fetch();
  },
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: true,
      },
      lockerCheckInStatusList: [],
      lockerCheckInStatusListGridColumn: [
        {
          field: "chkinId",
          allowEditing: false,
          type: "string",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "visitName",
          headerText: "내장객명",
          allowEditing: false,
          textAlign: "left",
        },
        {
          field: "startCourse",
          headerText: "코스",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          textAlign: "center",
          comCode: "COURSE_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "startTime",
          headerText: "시간",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          textAlign: "center",
        },
      ],
    };
  },
  methods: {
    async fetch() {
      const { bsnDate, lockerNo } = this._props;

      const {
        value: { lockerCheckInStatusList },
      } = await getLockerCheckInStatus(
          moment(bsnDate, "YYYYMMDD").format("YYYY-MM-DD"),
          lockerNo
      );

      this.lockerCheckInStatusList = lockerCheckInStatusList;
    },
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    hideDialog() {
      this.$refs["locker-check-in-status-dialog"].hide();
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    handleSubmitClick() {
      this.hideDialog();
      this.$emit("submit");
    },
    handleCloseClick() {
      this.hideDialog();
    },
  },
};
</script>

<style scoped></style>
