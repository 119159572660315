<template>
  <div
    id="powder-wrapper"
    class="content-body"
    @mousedown="onMouseDown"
  >
    <div
      v-if="propsLoading"
      class="loader"
    />
    <div class="powder-box" :style="{ zoom }">
      <div
        v-for="(item, index) in (floor === 1 ? backInfoFloor1 : backInfoFloor2)"
        :key="index"
        class="back-info"
        :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing ? item.wordSpacing : '',
          backgroundColor: item.backgroundColor,
          color: item.color,
          fontSize: `${propsIsFullscreen ? (item.fontSize ? item.fontSize : '') : (item.mFontSize || item.fontSize)}`,
          lineHeight: item.lineHeight ? item.lineHeight : '',
          border: item.border ? item.border : '',
          borderTop: item.borderTop ? item.borderTop : item.border,
          borderBottom: item.borderBottom ? item.borderBottom : item.border,
          borderLeft: item.borderLeft ? item.borderLeft : item.border,
          borderRight: item.borderRight ? item.borderRight : item.border,
        }"
      >
        <div
          v-if="item.isXLine"
          :style="{
            width: `${item.xLineWidth}`,
            height: `${item.xLineHeight}`,
            backgroundColor: `${item.xLineBgColor}`,
            transform: `rotate(${item.xLineRotate})`
          }"/>
        <div
          v-if="item.isYLine"
          :style="{
            width: `${item.yLineWidth}`,
            height: `${item.yLineHeight}`,
            backgroundColor: `${item.yLineBgColor}`,
            transform: `rotate(${item.yLineRotate})`
          }"/>
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
          v-for="data in propsLockerStatusList"
          :key="`${data.sector}_${data.lockerDiv}`"
          :class="`sector sector${data.sector}`"
        >
          <div
            v-for="board in getLockerBoardList(data.lockerBoardList)"
            :key="board.lockerNo"
            :class="[
              'locker',
              `locker${board.lockerNo}`,
              propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                ? 'search rainbow'
                : undefined,
              isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                ? 'clear'
                : undefined,
            ]"
            :style="{
              left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 31 : 31)}px`,
              top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
              ...getColorValue(board),
            }"
            @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 27px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>

<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";

export default {
  name: "LockerStatusH01",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom", "propsLockerStatusList", "propsSearchValue", "bsnDate", "propsFloor"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {},
  data() {
    return {
      zoom: 1,
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        1,
        false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
        'PART_DIV',
        '2',
        2,
        false,
      ),
      floor: 1,
    };
  },
  computed: {
    backInfoFloor1() {
      return [
        {
          w: 866,
          h: 218,
          x: 470,
          y: 533,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "A A A A",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 928,
          h: 130,
          x: 377,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "B B B",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 649,
          h: 218,
          x: 5,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "C C C",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 432,
          h: 218,
          x: 656,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "D D",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 928,
          h: 130,
          x: 377,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "E E E",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 432,
          h: 218,
          x: 1276,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "F F",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 215,
          h: 218,
          x: 1679,
          y: 225,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "F",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 339,
          h: 108,
          x: 5,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "S",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 339,
          h: 42,
          x: 5,
          y: 489,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "S",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 463,
          h: 86,
          x: 5,
          y: 643,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "S",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 28,
          h: 43,
          x: 1217,
          y: 179,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "UP<br>↑",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          lineHeight: "25px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 157,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 135,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 113,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 91,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 69,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 47,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1217,
          y: 28,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "↑",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          lineHeight: "25px",
          wordSpacing: "180px",
          borderTop: "none",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 56,
          h: 18,
          x: 1219,
          y: 6,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "→",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          wordSpacing: "180px",
        },
        {
          w: 28,
          h: 19,
          x: 1246,
          y: 28,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "↓",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 48,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 70,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 92,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 114,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 136,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 158,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 180,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 28,
          h: 21,
          x: 1246,
          y: 202,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "none",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 89,
          h: 18,
          x: 67,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "91px",
          xLineBgColor: "gray",
          xLineRotate: "-78deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "91px",
          yLineBgColor: "gray",
          yLineRotate: "78deg",
        },
        {
          w: 27,
          h: 18,
          x: 749,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "34px",
          xLineBgColor: "gray",
          xLineRotate: "-57deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "34px",
          yLineBgColor: "gray",
          yLineRotate: "57deg",
        },
        {
          w: 58,
          h: 18,
          x: 1369,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 89,
          h: 18,
          x: 222,
          y: 335,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "90px",
          xLineBgColor: "gray",
          xLineRotate: "-78deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "90px",
          yLineBgColor: "gray",
          yLineRotate: "78deg",
        },
        {
          w: 58,
          h: 18,
          x: 470,
          y: 357,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 58,
          h: 18,
          x: 470,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 58,
          h: 18,
          x: 811,
          y: 357,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 58,
          h: 18,
          x: 811,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 58,
          h: 18,
          x: 1152,
          y: 357,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 58,
          h: 18,
          x: 1152,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "61px",
          xLineBgColor: "gray",
          xLineRotate: "-73deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "61px",
          yLineBgColor: "gray",
          yLineRotate: "73deg",
        },
        {
          w: 89,
          h: 18,
          x: 129,
          y: 533,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "90px",
          xLineBgColor: "gray",
          xLineRotate: "-78deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "90px",
          yLineBgColor: "gray",
          yLineRotate: "78deg",
        },
        {
          w: 27,
          h: 18,
          x: 284,
          y: 731,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "33px",
          xLineBgColor: "gray",
          xLineRotate: "-56deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "33px",
          yLineBgColor: "gray",
          yLineRotate: "56deg",
        },
        {
          w: 58,
          h: 18,
          x: 563,
          y: 731,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 27,
          h: 18,
          x: 1214,
          y: 731,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "33px",
          xLineBgColor: "gray",
          xLineRotate: "-56deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "33px",
          yLineBgColor: "gray",
          yLineRotate: "56deg",
        },
        // 하단 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 0,
          y: 757,
          mx: 1758,
        },
      ];
    },
    backInfoFloor2() {
      return [
        {
          w: 277,
          h: 196,
          x: 98,
          y: 555,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "A",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 432,
          h: 218,
          x: 377,
          y: 533,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "B B",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 680,
          h: 130,
          x: 129,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "C C C",
          color: `rgb(255,0,0)`,
          wordSpacing: "198px",
        },
        {
          w: 215,
          h: 218,
          x: 129,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 246,
          h: 218,
          x: 346,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 215,
          h: 218,
          x: 594,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 215,
          h: 218,
          x: 811,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 680,
          h: 130,
          x: 129,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "E E E",
          color: `rgb(255,0,0)`,
          wordSpacing: "198px",
        },
        {
          w: 153,
          h: 240,
          x: 842,
          y: 511,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "F",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 155,
          h: 240,
          x: 904,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 186,
          h: 240,
          x: 1059,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 310,
          h: 240,
          x: 1245,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G G",
          wordSpacing: "115px",
          color: `rgb(255,0,0)`,
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 186,
          h: 240,
          x: 1555,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G",
          wordSpacing: "180px",
          color: `rgb(255,0,0)`,
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 153,
          h: 240,
          x: 1741,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G",
          wordSpacing: "180px",
          color: `rgb(255,0,0)`,
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 186,
          h: 152,
          x: 997,
          y: 511,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G",
          wordSpacing: "180px",
          color: `rgb(255,0,0)`,
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 649,
          h: 152,
          x: 1183,
          y: 511,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "G G G",
          wordSpacing: "178px",
          color: `rgb(255,0,0)`,
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 122,
          h: 240,
          x: 5,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "S",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 990,
          h: 24,
          x: 904,
          y: 487,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "가 변 락 카",
          fontSize: "18px",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
          backgroundColor: `rgba(250, 192, 0, .5)`,
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 465,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 443,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 421,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 399,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 377,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 355,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 333,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 311,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderTop: "1px solid rgb(0, 0, 0)",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 839,
          y: 292,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "↓",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          wordSpacing: "180px",
          borderRight: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 62,
          h: 18,
          x: 842,
          y: 270,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "←",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          wordSpacing: "180px",
        },
        {
          w: 31,
          h: 19,
          x: 871,
          y: 292,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "↑",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 312,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 334,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 356,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 378,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 400,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 21,
          x: 871,
          y: 422,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          borderBottom: "1px solid rgb(0, 0, 0)",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 31,
          h: 43,
          x: 871,
          y: 444,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          text: "DN<br>↑",
          color: "rgb(0,0,0)",
          fontSize: "12px",
          lineHeight: "25px",
          borderLeft: "1px solid rgb(0, 0, 0)",
        },
        {
          w: 58,
          h: 18,
          x: 656,
          y: 5,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 58,
          h: 18,
          x: 1121,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 58,
          h: 18,
          x: 1617,
          y: 247,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 58,
          h: 18,
          x: 439,
          y: 357,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 58,
          h: 18,
          x: 439,
          y: 379,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 58,
          h: 18,
          x: 687,
          y: 731,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "60px",
          xLineBgColor: "gray",
          xLineRotate: "-72deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "60px",
          yLineBgColor: "gray",
          yLineRotate: "72deg",
        },
        {
          w: 27,
          h: 18,
          x: 966,
          y: 643,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "33px",
          xLineBgColor: "gray",
          xLineRotate: "-56deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "33px",
          yLineBgColor: "gray",
          yLineRotate: "56deg",
        },
        {
          w: 27,
          h: 18,
          x: 966,
          y: 665,
          mw: 990,
          mh: 37,
          mx: 5,
          my: 5,
          wordSpacing: "180px",
          backgroundColor: `lightGray`,
          border: "1px solid gray",
          isXLine: true,
          xLineWidth: "1px",
          xLineHeight: "33px",
          xLineBgColor: "gray",
          xLineRotate: "-56deg",
          isYLine: true,
          yLineWidth: "1px",
          yLineHeight: "33px",
          yLineBgColor: "gray",
          yLineRotate: "56deg",
        },
        // 하단 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 0,
          y: 756,
          mx: 1758,
        },
        // 우측 여백 공간 조성을 위함.
        {
          w: 10,
          h: 10,
          x: 1870,
          y: 0,
          mx: 1758,
        },
      ];
    },
  },
  watch: {
    propsZoom() {
      this.zoom = this.propsZoom;
    },
    propsFloor() {
      this.floor = this.propsFloor;
    },
  },
  mounted() {},
  methods: {
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
        ? print.substr(0, 3)
        : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
        ? '#9764b6'
        : !["CHECKIN","ASSIGN","KEEP"].includes(board.lockerStatus) && board.vipFlag
          ? '#AAF0D1'
          : (commonCodesGetColorValue(
          'LOCKER_STATUS',
          board.lockerStatus === "KEEP" ? "RESVE" : board.lockerStatus,
            ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    },
    getLockerBoardList(data) {
      return data.filter(item => item.floor === this.floor);
    }
  }
};
</script>