<template>
  <div id="search-options-details">
    <ejs-dialog
      width="355"
      ref="search-options-details-dialog"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :header="'조건검색'"
      @close="handleCloseDialog"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">락카구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        cssClass="lookup-condition-dropdown"
                        :allowFiltering="false"
                        :dataSource="lockerDivOptions"
                        :fields="optionFields"
                        v-model="value.lockerDiv"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">락카번호</div>
                  <ul class="content">
                    <li class="item" style="width: 104px">
                      <input-text v-model="value.lockerNoFrom" />
                    </li>
                    <li class="item text">~</li>
                    <li class="item" style="width: 104px">
                      <input-text v-model="value.lockerNoTo" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">락카상태</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        cssClass="lookup-condition-dropdown"
                        :allowFiltering="false"
                        :dataSource="lockerStatusOptions"
                        :fields="optionFields"
                        v-model="value.lockerStatus"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  @click.native="handleSearchClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="handleResetClick"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="hideDialog"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SearchLockerInformationOptionsDetailsPopup",
  components: {
    InputText,
    ErpButton,
  },
  props: ["value"],
  created() {
    // 검색 포맷 추가
    this.lockerDivOptions.unshift({
      comCode: "",
      comName: "전체",
    });
    this.lockerDivOptions.push({
      comCode: "A",
      comName: "가변",
    });
    this.lockerStatusOptions.unshift({
      comCode: "",
      comName: "전체",
    });
  },
  data() {
    return {
      lockerDivOptions: commonCodesGetCommonCode("LOCKER_DIV"),
      lockerStatusOptions: commonCodesGetCommonCode("LOCKER_STATUS"),
      optionFields: { text: "comName", value: "comCode" },
    };
  },
  methods: {
    hideDialog() {
      this.$refs["search-options-details-dialog"].hide();
    },
    handleSearchClick() {
      this.$emit("submit");
    },
    handleResetClick() {
      this.hideDialog();
      this.$emit("reset");
    },
    handleCloseDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
