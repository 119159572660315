<template>
  <div>
    <div>
      <erp-button
          :is-icon-custom="true"
          v-on:click.native="handleBeforeWindowClick"
      >
        &lt;
      </erp-button>
      <erp-button
          :is-icon-custom="true"
          v-on:click.native="handleBeforeWindowClick"
      >
        &gt;
      </erp-button>
      {{ bsnDateFrom }} ~ {{ bsnDateTo }}
    </div>
    <ejs-grid
      ref="grid"
      id="grid-locker-daily-information"
      height="100%"
      width="100%"
      gridLines="Both"
      rowHeight="24"
      :dataSource="dataSource"
      :selectionSettings="selectionOptions"
      :editSettings="editSettings"
      :pageSettings="pageSettings"
      :allowPaging="true"
      :allowSelection="true"
      :allowResizing="true"
      :queryCellInfo="queryCellInfo"
    >
      <e-columns>
        <e-column
          field="no"
          headerText="NO"
          :allowEditing="false"
          width="50"
          :template="numberTemplate"
          textAlign="Center"
        />
        <e-column
          :width="60"
          :allowSelection="false"
          :headerText="'락카번호'"
          :textAlign="'Center'"
          :type="'string'"
          :displayAsCheckBox="false"
          :field="'lockerNo'"
          :allowEditing="false"
        />
        <e-column headerText="일자" :columns="columns" />
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import Vue from "vue";
import moment from "moment";
import {
  Edit,
  ForeignKey,
  Group,
  Page,
  Resize,
  Selection,
  Sort,
} from "@syncfusion/ej2-vue-grids";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import ErpButton from "@/components/button/ErpButton";
const SIZE_WINDOW = 30;

export default {
  name: "LockerDailyInformationGrid",
  mixins: [commonMixin],
  props: ["bsnDate", "dataSource", "dailyInfos"],
  components: {
    ErpButton,
  },
  provide: {
    grid: [Edit, Resize, ForeignKey, Group, Page, Selection, Sort],
  },
  created() {
    const { bsnDate } = this._props;

    this.bsnDateFrom = moment(bsnDate).format("YYYY-MM-DD");
    this.bsnDateTo = moment(bsnDate)
      .add(SIZE_WINDOW - 1, "days")
      .format("YYYY-MM-DD");

    this.initColumns();
  },
  mounted() {
    const { bsnDate } = this._props;

    this.bsnDateFrom = moment(bsnDate).format("YYYY-MM-DD");
    this.bsnDateTo = moment(bsnDate)
      .add(SIZE_WINDOW - 1, "days")
      .format("YYYY-MM-DD");

    this.initColumns();

    this.$EventBus.$on("checkInCellClick", this.handleCheckInCellClick);
    this.$EventBus.$on("resveOutOfCellClick", this.handleResveOutOfCellClick);
  },
  beforeUpdate() {
    const { bsnDate } = this._props;

    this.bsnDateFrom = moment(bsnDate).format("YYYY-MM-DD");
    this.bsnDateTo = moment(bsnDate)
      .add(SIZE_WINDOW - 1, "days")
      .format("YYYY-MM-DD");

    this.initColumns();
  },
  destroyed() {
    this.$EventBus.$off("checkInCellClick");
    this.$EventBus.$off("resveOutOfCellClick");
  },
  data() {
    return {
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      lockerStatusCommonCodes: commonCodesGetCommonCode("LOCKER_STATUS"),
      columns: [],
      selectionOptions: {
        cellSelectionMode: "BoxWithBorder",
        type: "Multiple",
        mode: "Cell",
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      pageSettings: {
        pageSize: 50,
        currentPage: 1,
      },
      bsnDateFrom: null,
      bsnDateTo: null,
    };
  },
  methods: {
    initColumns() {
      const bsnDate = this.bsnDateFrom;

      const columns = [];
      for (let i = 0; i < SIZE_WINDOW; i++) {
        const pDate = moment(bsnDate).locale("ko").add(i, "days");

        const filterDailys = this.dailyInfos.filter(
          (data) => data.bsnDate == pDate.format("YYYY-MM-DD")
        );

        const hldyFlag =
          filterDailys.length > 0 ? filterDailys[0].hldyFlag : false;
        const dwCode = filterDailys.length > 0 ? filterDailys[0].dwCode : null;

        columns.push({
          width: 40,
          headerTemplate:
            '<div style="margin-bottom: -14px; color: ' +
            (hldyFlag || dwCode === 1
              ? "#ff0000"
              : commonCodesGetColorValue("DW_CODE", dwCode.toString())) +
            '">' +
            pDate.format("ddd") +
            "</div><div>" +
            pDate.format("M/D") +
            "</div>",
          textAlign: "Center", // Left, Center, Right
          type: "custom",
          field: pDate.format("YYYYMMDD"),
          allowEditing: false,
          template() {
            return {
              template: Vue.component("LockerDailyInformationGridCell", {
                template: `
                  <span
                    :style="{
                      cursor: (getLockerStatus(data) === 'CHECKIN' || getLockerStatus(data) === 'RESVE' || getLockerStatus(data) === 'OUTOF') ? 'pointer' : undefined,
                      textDecoration: (getLockerStatus(data) === 'CHECKIN' || getLockerStatus(data) === 'RESVE' || getLockerStatus(data) === 'OUTOF') ? 'underline' : undefined,
                    }"
                    @click="handleClick(data)"
                  >
                    {{getLockerValue(getLockerStatus(data))}}
                  </span>
                `,
                data() {
                  return {
                    lockerStatusCommonCodes: commonCodesGetCommonCode(
                      "LOCKER_STATUS"
                    ),
                    data: {},
                  };
                },
                methods: {
                  getLockerStatus(data) {
                    const {
                      column: { field },
                    } = data;

                    return data[field];
                  },
                  getLockerValue(lockerStatus) {
                    const { comName } = this.lockerStatusCommonCodes.find(
                      ({ comCode }) => comCode === lockerStatus
                    );

                    return comName;
                  },
                  handleClick(data) {
                    if (
                      this.getLockerStatus(data) !== "CHECKIN" &&
                      this.getLockerStatus(data) !== "RESVE" &&
                      this.getLockerStatus(data) !== "OUTOF"
                    ) {
                      return;
                    }

                    const {
                      lockerNo,
                      column: { field: bsnDate },
                    } = data;
                    const lockerStatus = this.getLockerStatus(data);

                    if (this.getLockerStatus(data) === "CHECKIN") {
                      this.$EventBus.$emit("checkInCellClick", {
                        bsnDate,
                        lockerNo,
                      });
                    } else if (
                      this.getLockerStatus(data) === "RESVE" ||
                      this.getLockerStatus(data) === "OUTOF"
                    ) {
                      this.$EventBus.$emit("resveOutOfCellClick", {
                        bsnDate,
                        lockerNo,
                        lockerStatus,
                      });
                    }
                  },
                },
              }),
            };
          },
        });
      }

      this.columns = columns;
    },
    handleBeforeWindowClick() {
      this.$emit("beforeWindowClick", {
        currBsnDate: this.bsnDateFrom,
      });
    },
    handleNextWindowClick() {
      this.$emit("nextWindowClick", {
        currBsnDate: this.bsnDateFrom,
      });
    },
    handleCheckInCellClick({ bsnDate, lockerNo }) {
      this.$emit("checkInCellClick", { bsnDate, lockerNo });
    },
    handleResveOutOfCellClick({ bsnDate, lockerNo, lockerStatus }) {
      this.$emit("resveOutOfCellClick", { bsnDate, lockerNo, lockerStatus });
    },
    getColorByLockerStatus(lockerStatus) {
      const { colorValue } = this.lockerStatusCommonCodes.find(
        ({ comCode }) => comCode === lockerStatus
      );

      return colorValue;
    },
    queryCellInfo(args) {
      const {
        data,
        column: { field, type },
      } = args;

      if (type === "custom") {
        args.cell.style.backgroundColor = this.getColorByLockerStatus(
          data[field]
        );
        args.cell.style.fontSize = data[field] === "EMPTY" ? "0" : "9px";
      } else {
        args.cell.style.backgroundColor = "#ffffff";
      }
    },
    getSelectedLockerInfoList() {
      const selectedRowCellIndexes = this.$refs.grid.getSelectedRowCellIndexes();

      const selectedMatrix = [];
      selectedRowCellIndexes.forEach(({ rowIndex, cellIndexes }) => {
        cellIndexes.forEach((cellIndex) => {
          cellIndex >= 2 &&
            selectedMatrix.push({ row: rowIndex, col: cellIndex });
        });
      });

      const currentViewRecords = this.$refs.grid.ej2Instances.getCurrentViewRecords();
      return selectedMatrix.map(({ row, col }) => {
        const rowData = currentViewRecords[row];
        const column = this.columns[col - 2];

        const bsnDate = moment(column.field, "YYYYMMDD").format("YYYY-MM-DD");
        const lockerNo = rowData.lockerNo;
        const lockerStatus = rowData[column.field];

        return {
          bsnDate,
          lockerNo,
          lockerStatus,
        };
      });
    },
  },
};
</script>

<style scoped></style>
