<template>
  <div id="create-locker-batch">
    <ejs-dialog
      ref="create-locker-batch-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :header="'일괄락카생성'"
      @close="handleCloseDialog"
    >
      <div class="window lockerCreate">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="datepickerBsnDateFrom"
                                        format="YYYY-MM-DD"
                                        cssClass="lookup-condition-datepicker"
                                        v-model="bsnDateFromValueModel"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">~</li>
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="datepickerBsnDateTo"
                                        format="YYYY-MM-DD"
                                        cssClass="lookup-condition-datepicker"
                                        v-model="bsnDateToValueModel"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="handleCreateClick"
              >
                생성
              </erp-button>
            </li>
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  @click.native="handleDeleteClick"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import moment from "moment";
import { getFormattedDate } from "@/utils/date";
import InputDate from "@/components/common/datetime/InputDate";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CreateLockerInformationInBatchPopup",
  mixins: [commonMixin],
  components: {
    InputDate,
    ErpButton,
  },
  props: ["bsnDate"],
  beforeMount() {
    const { bsnDate } = this._props;
    this.bsnDateFrom = new Date(moment(bsnDate).format("YYYY-MM-DD"));
    this.bsnDateTo = new Date(
      moment(this.bsnDateFrom).add(30, "days").format("YYYY-MM-DD")
    );
  },
  data() {
    return {
      bsnDateFrom: null,
      bsnDateTo: null,
    };
  },
  computed: {
    bsnDateFromValueModel: {
      get() {
        return getFormattedDate(this.bsnDateFrom);
      },
      set(bsnDateFromValueModel) {
        const bsnDate = moment(bsnDateFromValueModel);
        if (bsnDate.isValid()) {
          this.bsnDateFrom = bsnDate.toDate();
        }
      },
    },
    bsnDateToValueModel: {
      get() {
        return getFormattedDate(this.bsnDateTo);
      },
      set(bsnDateToValueModel) {
        const bsnDate = moment(bsnDateToValueModel);
        if (bsnDate.isValid()) {
          this.bsnDateTo = bsnDate.toDate();
        }
      },
    },
  },
  methods: {
    hideDialog() {
      this.$refs["create-locker-batch-dialog"].hide();
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    handleCreateClick() {
      const bsnDateFrom = moment(this.bsnDateFrom).format("YYYY-MM-DD");
      const bsnDateTo = moment(this.bsnDateTo).format("YYYY-MM-DD");

      this.hideDialog();
      this.$emit("create", {
        bsnDateFrom,
        bsnDateTo,
      });
    },
    handleDeleteClick() {
      const bsnDateFrom = moment(this.bsnDateFrom).format("YYYY-MM-DD");
      const bsnDateTo = moment(this.bsnDateTo).format("YYYY-MM-DD");

      this.hideDialog();
      this.$emit("delete", {
        bsnDateFrom,
        bsnDateTo,
      });
    },
    handleCloseClick() {
      this.hideDialog();
    },
  },
};
</script>

<style scoped></style>
