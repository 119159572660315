<template>
  <div id="locker-status-confirm">
    <ejs-dialog
      ref="locker-status-confirm-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :header="`락카 상태 설정 [선택 ${targetLockersCount}개]`"
      @close="handleCloseDialog"
    >
      <div class="window lockerStatusSettings">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-text">
                      선택한 락카 상태를
                      <strong
                        >"{{ getComNameAsLockerStatus(lockerStatus) }}"</strong
                      >로 변경하시겠습니까?
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  is-icon-custom="true"
                  @click.native="handleSubmitClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="handleCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfirmSetLockerStatusPopup",
  mixins: [commonMixin],
  props: ["targetLockersCount", "lockerStatus"],
  components: {
    ErpButton,
  },
  data() {
    return {
      lockerStatusCommonCodes: commonCodesGetCommonCode("LOCKER_STATUS"),
    };
  },
  methods: {
    hideDialog() {
      this.$refs["locker-status-confirm-dialog"].hide();
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    getComNameAsLockerStatus(lockerStatus) {
      const { comName } = this.lockerStatusCommonCodes.find(
        ({ comCode }) => comCode === lockerStatus
      );

      return comName;
    },
    handleSubmitClick() {
      this.hideDialog();
      this.$emit("submit", {});
    },
    handleCloseClick() {
      this.hideDialog();
    },
  },
};
</script>

<style scoped></style>
