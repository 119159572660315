<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="lockerResveAndOutOfStatusPopup"
      :header="`락카 ${commonCodesGetComName(
        'LOCKER_STATUS',
        lockerStatus
      )} 현황`"
      width="301"
      :animationSettings="{ effect: 'None' }"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      @close="onLockerResveAndOutOfStatusPopupClosed"
    >
      <div class="window lockerReservation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li v-if="lockerStatus === 'RESVE'" class="field">
                              <!-- 필수 : required -->
                              <div class="title">예약자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ lockerDailyInfo.resveName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">비고사항</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ lockerDailyInfo.remarks }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeLockerResveAndOutOfStatusPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetComName } from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LockerResveAndOutOfStatusPopup",
  mixins: [commonMixin],
  props: ["bsnDate", "lockerNo", "lockerStatus"],
  components: {
    ErpButton,
  },
  data() {
    return {
      lockerDailyInfo: {
        resveName: null,
        remarks: null,
      },
    };
  },
  methods: {
    commonCodesGetComName,
    showLockerResveAndOutOfStatusPopup() {
      this.fetchLockerDailyInfo();
    },
    onLockerResveAndOutOfStatusPopupClosed() {
      this.$emit("popupClosed");
    },
    closeLockerResveAndOutOfStatusPopup() {
      this.$refs.lockerResveAndOutOfStatusPopup.hide();
    },
    async fetchLockerDailyInfo() {
      const data = await GolfErpAPI.fetchLockerDailyInfo(
        this.bsnDate,
        this.lockerNo
      );

      this.lockerDailyInfo.resveName = data.resveName;
      this.lockerDailyInfo.remarks = data.remarks;
    },
  },
};
</script>
