<template>
  <div
      id="powder-wrapper"
      class="content-body"
      @mousedown="onMouseDown"
  >
    <div
        v-if="propsLoading"
        class="loader"
    />
    <div class="powder-box" :style="{ zoom }">
      <div
          v-for="(item, index) in (
              lockerDiv === 'M'
                ? variableFlag === false
                  ? backInfoLockerDiv1
                  :backInfoLockerDiv3
                : backInfoLockerDiv2)"
          :key="index"
          class="back-info"
          :style="{
          width: `${propsIsFullscreen ? item.w : (item.mw || item.w)}px`,
          height: `${propsIsFullscreen ? item.h : (item.mh || item.h)}px`,
          left: `${propsIsFullscreen ? item.x : (item.mx || item.x)}px`,
          top: `${propsIsFullscreen ? item.y : (item.my || item.y)}px`,
          wordSpacing: item.wordSpacing ? item.wordSpacing : '',
          backgroundColor: item.backgroundColor,
          color: item.color,
          fontSize: `${propsIsFullscreen ? (item.fontSize ? item.fontSize : '') : (item.mFontSize || item.fontSize)}`,
          lineHeight: item.lineHeight ? item.lineHeight : '',
          border: item.border ? item.border : '',
          borderTop: item.borderTop ? item.borderTop : item.border,
          borderBottom: item.borderBottom ? item.borderBottom : item.border,
          borderLeft: item.borderLeft ? item.borderLeft : item.border,
          borderRight: item.borderRight ? item.borderRight : item.border,
        }"
      >
        <div
            v-if="item.isXLine"
            :style="{
            width: `${item.xLineWidth}`,
            height: `${item.xLineHeight}`,
            backgroundColor: `${item.xLineBgColor}`,
            transform: `rotate(${item.xLineRotate})`
          }"/>
        <div
            v-if="item.isYLine"
            :style="{
            width: `${item.yLineWidth}`,
            height: `${item.yLineHeight}`,
            backgroundColor: `${item.yLineBgColor}`,
            transform: `rotate(${item.yLineRotate})`
          }"/>
        <span v-html="item.text"/>
      </div>
      <div class="powder">
        <div
            v-for="data in propsLockerStatusList"
            :key="`${data.sector}_${data.lockerDiv}`"
            :class="`sector sector${data.sector}`"
        >
          <div
              v-for="board in getLockerBoardList(data.lockerBoardList)"
              :key="board.lockerNo"
              :class="[
              'locker',
              `locker${board.lockerNo}`,
              propsSearchValue === board.lockerPrtNo || propsSearchValue === getResizingPrintLabel(board.lockerPrtNo)
                ? 'search rainbow'
                : undefined,
              isNaN(board.lockerPrtNo) && propsViewDiv === 'VISIT'
                ? 'clear'
                : undefined,
            ]"
              :style="{
              left: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) % 100) * (propsIsFullscreen ? 40 : 35)}px`,
              top: `${Math.floor((propsIsFullscreen ? board.sortNo : board.sortNoMin) / 100) * (propsIsFullscreen ? 22 : 19)}px`,
              ...getColorValue(board),
            }"
              @click="openLockerKeyAssignPopup(board.lockerNo)"
          >
            {{ getResizingPrintLabel(board.lockerPrtNo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*body .content-body {background-image: linear-gradient(90deg, #dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf 0px, #dfdfdf 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(#efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, transparent 100px), linear-gradient(transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(90deg, #efefef 0px, #efefef 1px, transparent 1px, transparent 99px, #efefef 99px, #efefef 100px), linear-gradient(90deg, transparent 0px, transparent 5px, #fff 5px, #fff 95px, transparent 95px, transparent 100px), linear-gradient(transparent 0px, transparent 1px, #fff 1px, #fff 99px, transparent 99px, transparent 100px), linear-gradient(#dfdfdf, #dfdfdf);background-size: 100px 100%, 100% 100px, 100% 10px, 10px 100%, 100% 100px, 100px 100%, 100px 100%, 100px 100px, 100px 100px;cursor: grab;overflow: hidden;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none}*/
#powder-wrapper {position: relative}
#powder-wrapper > .loader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, .5); backdrop-filter: blur(20px); z-index: 1}
.powder-box {display: flex;justify-content: space-between;position: absolute;width: 1114px;padding: 5px}
.powder-box > .back-info {display: flex;align-items: center;justify-content: center;line-height: 50px;position: absolute;color: rgba(0, 0, 0, .25);font-size: 36px;font-weight: bold;text-align: center}
.powder-box > .powder {width: 340px;color: #333;border-right: 1px solid #D9E5FF;background-color: #D9E5FF}
.powder-box > .powder > .sector {display: flex;position: relative}
.powder-box > .powder > .sector > .locker {display: flex;align-items: center;justify-content: center;width: 34px;height: 18px;position: absolute;border: 1px solid rgba(0, 0, 0, .1);color: #000;font-family: NanumSquare-Regular, serif;font-size: 12px;line-height: 14px;overflow: hidden;cursor: pointer}
.powder-box > .powder > .sector > .locker.search {z-index: 1;}
.powder-box > .powder > .sector > .locker.clear {letter-spacing: -2px;}
.powder-box > .powder > .sector > .locker:hover {color: #fff !important;background-color: #2e7458 !important}
.rainbow {animation: rainbow 2.5s linear;animation-iteration-count: infinite;}
@keyframes rainbow {
  100%, 0% {border-color: rgb(255,0,0);outline: 1px solid rgb(255,0,0);}
  8% {border-color: rgb(255,127,0);outline: 1px solid rgb(255,127,0);}
  16% {border-color: rgb(255,255,0);outline: 1px solid rgb(255,255,0);}
  25% {border-color: rgb(127,255,0);outline: 1px solid rgb(127,255,0);}
  33% {border-color: rgb(0,255,0);outline: 1px solid rgb(0,255,0);}
  41% {border-color: rgb(0,255,127);outline: 1px solid rgb(0,255,127);}
  50% {border-color: rgb(0,255,255);outline: 1px solid rgb(0,255,255);}
  58% {border-color: rgb(0,127,255);outline: 1px solid rgb(0,127,255);}
  66% {border-color: rgb(0,0,255);outline: 1px solid rgb(0,0,255);}
  75% {border-color: rgb(127,0,255);outline: 1px solid rgb(127,0,255);}
  83% {border-color: rgb(255,0,255);outline: 1px solid rgb(255,0,255);}
  91% {border-color: rgb(255,0,127);outline: 1px solid rgb(255,0,127);}
}
</style>


<script>
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";

export default {
  name: "LockerStatusJY1",
  props: ["propsIsFullscreen", "propsViewDiv", "propsLoading", "propsZoom", "propsLockerStatusList", "propsSearchValue", "bsnDate", "propsLockerDiv", "propsVariableFlag" ],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {},
  data() {
    return {
      zoom: 1,
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          1,
          false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          2,
          false,
      ),
      floor: 1,
      lockerDiv: "M",
      variableFlag: false,
    };
  },
  computed: {
    backInfoLockerDiv1() {
      return [
        {
          w: 437,
          h: 197,
          x: 45,
          y: 290,
          mw: 340,
          mh: 172,
          mx: 30,
          my: 247,
          text: "M",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 437,
          h: 150,
          x: 45,
          y: 490,
          mw: 340,
          mh: 102,
          mx: 39,
          my: 425,
          text: "L",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 437,
          h: 150,
          x: 45,
          y: 645,
          mw: 390,
          mh: 132,
          mx: 39,
          my: 558,
          text: "K",
          color: `rgb(255,0,0)`,
          wordSpacing: "180px",
        },
        {
          w: 397,
          h: 107,
          x: 565,
          y: 755,
          mw: 352,
          mh: 94,
          mx: 494,
          my: 652,
          text: "J",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 397,
          h: 107,
          x: 565,
          y: 645,
          mw: 352,
          mh: 94,
          mx: 494,
          my: 559,
          text: "I",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 397,
          h: 107,
          x: 565,
          y: 535,
          mw: 352,
          mh: 94,
          mx: 494,
          my: 462,
          text: "H",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 397,
          h: 195,
          x: 565,
          y: 335,
          mw: 352,
          mh: 174,
          mx: 494,
          my: 288,
          text: "G",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 160,
          h: 285,
          x: 565,
          y: 5,
          mw: 139,
          mh: 251,
          mx: 495,
          my: 5,
          text: "F",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 277,
          h: 285,
          x: 725,
          y: 5,
          mw: 250,
          mh: 251,
          mx: 632,
          my: 5,
          text: "E",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 1045,
          y: 5,
          mw: 321,
          mh: 134,
          mx: 914,
          my: 5,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 1045,
          y: 160,
          mw: 321,
          mh: 134,
          mx: 914,
          my: 138,
          text: "C",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
      ];
    },
    backInfoLockerDiv2() {
      return [
        {
          w: 357,
          h: 85,
          x: 45,
          y: 5,
          mw: 320,
          mh: 75,
          mx: 40,
          my: 5,
          text: "G",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 45,
          y: 95,
          mw: 320,
          mh: 135,
          mx: 40,
          my: 82,
          text: "E",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 45,
          y: 248,
          mw: 320,
          mh: 135,
          mx: 40,
          my: 212,
          text: "F",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 525,
          y: 5,
          mw: 320,
          mh: 135,
          mx: 460,
          my: 5,
          text: "A",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 525,
          y: 158,
          mw: 320,
          mh: 135,
          mx: 460,
          my: 138,
          text: "B",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 525,
          y: 313,
          mw: 320,
          mh: 135,
          mx: 460,
          my: 271,
          text: "C",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 525,
          y: 466,
          mw: 320,
          mh: 135,
          mx: 460,
          my: 403,
          text: "D",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
      ];
    },
    backInfoLockerDiv3() {
      return [
        {
          w: 357,
          h: 152,
          x: 245,
          y: 71,
          mw: 317,
          mh: 136,
          mx: 215,
          my: 61,
          text: "JB",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 357,
          h: 152,
          x: 245,
          y: 225,
          mw: 317,
          mh: 136,
          mx: 215,
          my: 195,
          text: "JA",
          color: `rgb(255,0,0)`,
          wordSpacing: "275px",
        },
        {
          w: 10,
          h: 10,
          x: 1888,
          y: 0,
          mx: 1758,
        },
      ];
    },
  },
  watch: {
    propsZoom() {
      this.zoom = this.propsZoom;
    },
    propsFloor() {
      this.floor = this.propsFloor;
    },
    propsLockerDiv() {
      this.lockerDiv = this.propsLockerDiv;
    },
    propsVariableFlag() {
      this.variableFlag = this.propsVariableFlag;
    },
  },
  mounted() {},
  methods: {
    getResizingPrintLabel(print) {
      return isNaN(print) && this.propsViewDiv === 'VISIT'
          ? print.substr(0, 3)
          : print;
    },
    openLockerKeyAssignPopup(lockerNo) {
      this.$emit("openLockerKeyAssignPopup", lockerNo);
    },
    getColorValue(board) {
      const backgroundColor = board.startTime >= this.partDiv2StartTime && board.startTime <= this.partDiv2EndTime
          ? '#9764b6'
          : !["CHECKIN","ASSIGN","KEEP"].includes(board.lockerStatus) && board.vipFlag
              ? '#AAF0D1'
              : (commonCodesGetColorValue(
                  'LOCKER_STATUS',
                  board.lockerStatus === "KEEP" ? "RESVE" : board.lockerStatus,
              ) || '#fff');
      let colorCount = 0;
      if (backgroundColor) {
        const addColorCount = v => colorCount += v < 128 ? 1 : 0;
        const {r, g, b} = this.hexToRgb(backgroundColor);
        [r, g, b].forEach(i => addColorCount(i));
      }
      return {
        color: colorCount >= 2 ? '#FFF' : '#000',
        backgroundColor,
      };
    },
    onMouseDown(event) {
      this.$emit("onMouseDown", event);
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
          : null;
    },
    getLockerBoardList(data){
      return data.filter(item => item.lockerDiv === this.lockerDiv && item.variableFlag === this.variableFlag);
    }
  }
};
</script>